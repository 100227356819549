
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AvukatTalepPicker from "@/components/pickers/uyap/AvukatTalepPicker.vue";
import AvukatTalepTebligatTuruPicker from "@/components/pickers/uyap/AvukatTalepTebligatTuruPicker.vue";
import AvukatTalepAdresTuruPicker from "@/components/pickers/uyap/AvukatTalepAdresTuruPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import AvukatTalepMalTuruRadio from "@/components/radio/uyap/AvukatTalepMalTuruRadio.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import UyapParaBirimiPicker from "@/components/pickers/uyap/UyapParaBirimiPicker.vue";
import UyapAlacakliPicker from "@/components/pickers/uyap/UyapAlacakliPicker.vue";
import Plaka from "@/components/inputs/Plaka.vue";
import HacizTalepBankaPicker from "@/components/pickers/uyap/HacizTalepBankaPicker.vue";
import IbanNumarasi from "@/components/inputs/IbanNumarasi.vue";
import GenelTalepHesapKimeAitAlacakliRadio from "@/components/radio/uyap/GenelTalepHesapKimeAitAlacakliRadio.vue";
import DosyaKapatmaNedenleriPicker from "@/components/pickers/uyap/DosyaKapatmaNedenleriPicker.vue";
import UyapAdliyePicker from "@/components/pickers/uyap/UyapAdliyePicker.vue";
import HacizTalepMaasHacziCalismaDurumuPicker
  from "@/components/pickers/uyap/HacizTalepMaasHacziCalismaDurumuPicker.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import UyapHacizTalepForm from "@/components/forms/UyapHacizTalepForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {UyapTebligatEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapTebligatEvrakTuru";
import IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm.vue";
import KiymetTakdirininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/KiymetTakdirininTebligeCikartilmasiTalepEvrakForm.vue";
import YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm.vue";
import BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm.vue";
import YenilemeEmrininTebligeCikartilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/YenilemeEmrininTebligeCikartilmasiTalepEvrakForm.vue";
import TaahhuduKabulMuhtirasiTebligiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/TaahhuduKabulMuhtirasiTebligiTalepEvrakForm.vue";
import TemlikBilgisininBildirilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/tebligat-evrak/TemlikBilgisininBildirilmesiTalepEvrakForm.vue";
import {UyapDigerEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapDigerEvrakTuru";
import AcizBelgesiHazirlanmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/AcizBelgesiHazirlanmasiTalepEvrakForm.vue";
import AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm.vue";
import AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm.vue";
import BankadanHacizliParaninIstenilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/BankadanHacizliParaninIstenilmesiTalepEvrakForm.vue";
import BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm.vue";
import DosyadaHacizliMalinSatisiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadaHacizliMalinSatisiTalepEvrakForm.vue";
import DosyadakiAvansinIadesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiAvansinIadesiTalepEvrakForm.vue";
import DosyadakiHacizlerinKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiHacizlerinKaldirilmasiTalepEvrakForm.vue";
import DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm.vue";
import DosyaninIslemdenKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyaninIslemdenKaldirilmasiTalepEvrakForm.vue";
import DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm.vue";
import HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm.vue";
import HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm.vue";
import HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm.vue";
import HaricenTahsilatBildirimiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HaricenTahsilatBildirimiTalepEvrakForm.vue";
import Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm.vue";
import Iik150cSerhiEklenmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/Iik150cSerhiEklenmesiTalepEvrakForm.vue";
import KiymetTakdirininYapilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/KiymetTakdirininYapilmasiTalepEvrakForm.vue";
import MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm.vue";
import MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm.vue";
import RehinAcigiBelgesiHazirlanmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/RehinAcigiBelgesiHazirlanmasiTalepEvrakForm.vue";
import RehininParayaCevrilmesiSerhiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/RehininParayaCevrilmesiSerhiTalepEvrakForm.vue";
import TasinmazHaczininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/TasinmazHaczininKaldirilmasiTalepEvrakForm.vue";
import YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm.vue";
import {UyapHacizEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapHacizEvrakTuru";

@Component({
  components: {
    YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm,
    TasinmazHaczininKaldirilmasiTalepEvrakForm,
    RehininParayaCevrilmesiSerhiTalepEvrakForm,
    RehinAcigiBelgesiHazirlanmasiTalepEvrakForm,
    MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm,
    MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm,
    KiymetTakdirininYapilmasiTalepEvrakForm,
    Iik150cSerhiEklenmesiTalepEvrakForm,
    Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm,
    HaricenTahsilatBildirimiTalepEvrakForm,
    HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm,
    HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm,
    HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm,
    DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm,
    DosyaninIslemdenKaldirilmasiTalepEvrakForm,
    DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm,
    DosyadakiHacizlerinKaldirilmasiTalepEvrakForm,
    DosyadakiAvansinIadesiTalepEvrakForm,
    DosyadaHacizliMalinSatisiTalepEvrakForm,
    BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm,
    BankadanHacizliParaninIstenilmesiTalepEvrakForm,
    AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm,
    AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm,
    AcizBelgesiHazirlanmasiTalepEvrakForm,
    TemlikBilgisininBildirilmesiTalepEvrakForm,
    TaahhuduKabulMuhtirasiTebligiTalepEvrakForm,
    YenilemeEmrininTebligeCikartilmasiTalepEvrakForm,
    BakiyeBorcMuhtirasininTebligeCikartilmasiTalepEvrakForm,
    YuzUcDavetiyesininTebligeCikartilmasiTalepEvrakForm,
    KiymetTakdirininTebligeCikartilmasiTalepEvrakForm,
    IcraOdemeEmrininTebligeCikartilmasiTalepEvrakForm,
    DeleteDialog,
    UyapHacizTalepForm,
    FormDialog,
    HacizTalepMaasHacziCalismaDurumuPicker,
    UyapAdliyePicker,
    DosyaKapatmaNedenleriPicker,
    GenelTalepHesapKimeAitAlacakliRadio,
    IbanNumarasi,
    HacizTalepBankaPicker,
    Plaka,
    UyapAlacakliPicker,
    UyapParaBirimiPicker,
    Tutar,
    AvukatTalepMalTuruRadio,
    Dates,
    AvukatTalepAdresTuruPicker,
    AvukatTalepTebligatTuruPicker,
    AvukatTalepPicker,
    FormWrapper
  }
})
export default class UyapEvrakGonderForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) dosyaId!: number;
  @Prop({required: true}) kisiKurumId !: number;
  @Prop({required: true}) dosyaNo !: string;
  @Prop({required: true}) kisiMi !: boolean;

  localValue!: any;
  uyapHacizEvrakTuru = UyapHacizEvrakTuru;
  uyapTebligatEvrakTuru = UyapTebligatEvrakTuru;
  uyapDigerEvrakTuru = UyapDigerEvrakTuru;
  grupKodu = 0;

  hacizHeaders = [
    {text: 'Talep Türü', value: 'talepKisaAdi'},
    {text: 'Açıklama', value: 'aciklama'},
    {text: 'Masraf', value: 'talepMasrafi'},
    {text: 'İşlemler', value: 'actions', align: 'center', width: 100}
  ]

  get toplamMasraf() {
    let tutar = 0;
    if (this.grupKodu == 0 && Array.isArray(this.localValue)) {
      this.localValue.forEach((talep: any) => {
        if (talep.talepKodu == 4)
          tutar += talep.talepMasrafi * talep.bankaList.length;
        else
          tutar += talep.talepMasrafi;
      });
    } else if (this.grupKodu == 1)
      tutar += this.localValue.talepMasrafi;
    else if (this.grupKodu == 2)
      tutar += this.localValue.talepMasrafi;
    else
      tutar = 0;
    return tutar;
  }

  onTalepChange(e: any) {
    this.localValue = {...e};
    if (this.localValue.talepKodu == 41) {
      this.localValue.kismiTemlikMi = false;
      this.localValue.kismiTemlikMiStr = 'Hayır';
      this.localValue.temlikTipi = 1;
    }
    this.input();
  }

  @Watch('grupKodu')
  onGrupKoduChange() {
    if (this.grupKodu == 0) {
      this.localValue = [];
      this.input()
    } else {
      this.localValue = {};
      this.input();
    }
  }

  get hacizItems() {
    if (Array.isArray(this.localValue))
      return this.localValue;
    else
      return [];
  }

  onAdd(v: any) {
    this.localValue.push(v);
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
  }

  onUpdate(item: any, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  mounted() {
    this.onGrupKoduChange();
  }

  save() {
    if (this.grupKodu == 0) {
      if (this.localValue.length == 0) {
        this.$toast.info('Haciz talebi ekleyiniz.');
        return;
      }
      this.$uyap.IcraTalepEvrakHazirla().run({
        dosyaId: this.dosyaId,
        dosyaEsasNo: this.dosyaNo,
        kisiKurumId: this.kisiKurumId,
        talepBilgileri: JSON.stringify(this.localValue)
      }).then((dokuman) => {
        this.$imza.dokumanImzala(dokuman).then((imzaliDokuman: string) => {
          let imzaliDokumanBlob = this.$helper.base64toBlob(imzaliDokuman, "application/octet-stream");
          let formData = new FormData();
          let dokumanAdi = this.dosyaNo.replace('/', '_') + '_imzali.udf';
          let items = [
            {
              "id": 1,
              "evrakTuruOptionDVO": {
                "tur": "ICR_AVUKAT_PORTAL_HACIZ_TALEP",
                "id": "ICR_AVUKAT_PORTAL_HACIZ_TALEP",
                "label": "Haciz Talebi",
                "mandatory": true,
                "max": 1,
                "ekEvrakMax": 5
              },
              "tur": "ICR_AVUKAT_PORTAL_HACIZ_TALEP",
              "turAciklama": "Haciz Talebi",
              "mandatory": true,
              "file": {},
              "path": "C:\\fakepath\\" + dokumanAdi,
              "kullaniciEvrakAciklama": "Haciz Talebi",
              "parentId": -1,
              "fileId": 1
            }
          ];
          formData.append('file_1', imzaliDokumanBlob, dokumanAdi);
          formData.append('items', JSON.stringify(items));
          formData.append('dosyaId', this.dosyaId.toString());
          formData.append('kisiKurumId', this.kisiKurumId.toString());
          formData.append('tutar', this.toplamMasraf.toFixed(2));
          formData.append('talepGrupId', '0');
          formData.append('talepBilgileri', JSON.stringify(this.localValue));
          if (this.toplamMasraf > 0) {
            formData.append('vakifbankHesapBilgileri', 'undefined');
            formData.append('vakifbankOdemeIstekBilgileri', 'undefined');
            formData.append('smsSifre', 'undefined');
            formData.append('odemeTipi', '2');
          }
          this.$uyap.AvukatIcraTalepEvrakiGonder().run({formData: formData}).then((res) => {
            if (res.result == 'success')
              this.$toast.success('Haciz evrakı gönderme başarılı. ' + res.message);
            else
              this.$toast.error('Haciz evrakı gönderme başarısız. ' + res.message)
          })
        });
      });
    } else if (this.grupKodu == 1) {

    } else if (this.grupKodu == 2) {

    }
  }
}
