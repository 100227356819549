export enum TahsilHarci {
    r0090 = "0.9",
    r0180 = "1.8",
    r0198 = "1.98",
    r0227 = "2.27",
    r0360 = "3.60",
    r0396 = "3.96",
    r0455 = "4.55",
    r0720 = "7.2",
    r0792 = "7.92",
    r0900 = "9",
    r0910 = "9.1",
    r0990 = "9.9",
    r1138 = "11.38",
}

export  enum  TahsilHarciLabel {
    r0090 = "0,90",
    r0180 = "1,80",
    r0198 = "1,98",
    r0227 = "2,27",
    r0360 = "3,60",
    r0396 = "3,96",
    r0455 = "4,55",
    r0720 = "7,20",
    r0792 = "7,92",
    r0900 = "9,00",
    r0910 = "9,10",
    r0990 = "9,90",
    r1138 = "11,38"
}