
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {SgkMossipHacziTalep} from "@/interface/IUyapTalep";
import BorcluSgkHacizPicker from "@/components/pickers/uyap/BorcluSgkHacizPicker.vue";

@Component({
  components: {BorcluSgkHacizPicker}
})
export default class SgkMossipHacziTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() dosyaId!: string;
  @Prop() kisiKurumId!: number;
  localValue!: SgkMossipHacziTalep;
}
