export enum IlamsizBelgeTuru {
    cek = 1,
    police = 2,
    fatura = 3,
    kiraKontrat = 4,
    sozlesme = 5,
    tuketiciHakemHeyetiKarar = 8,
    senet = 11,
    diger=22,
}

export enum IlamsizBelgeTuruLabel {
    cek = "Çek",
    fatura = "Fatura",
    kiraKontrat = "Kira Kontratı",
    police = "Poliçe",
    senet = "Senet",
    sozlesme = "Sözleşme",
    tuketiciHakemHeyetiKarar = "Tüketici Hakem Heyeti Kararı",
    diger='Diğer'
}
