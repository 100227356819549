

import {Component, Prop, Vue} from "vue-property-decorator";
import draggable from 'vuedraggable';

@Component({
  components: {draggable}
})
export default class TahsilatDusmeSirasi extends Vue {
  @Prop({default: false}) kartaOzel!: boolean | string;
  @Prop() items!: Array<any>;

  onFly = false;
  tab = 0;

  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    };
  }

  get isKartaOzel() {
    return this.kartaOzel || this.kartaOzel === "";
  }

  get dusmeSirasiItems() {
    if (this.tab == 0) {
      return this.items.filter(item => item.takip_oncesi_dusme_sirasinda_gosterilsin == 1).sort((a, b) => (a.takip_oncesi_dusme_sirasi - b.takip_oncesi_dusme_sirasi));
    } else {
      return this.items.sort((a, b) => (a.takip_sonrasi_dusme_sirasi - b.takip_sonrasi_dusme_sirasi));
    }
  }

  onEndNew(evt: any) {
    if (evt.oldIndex == evt.newIndex)
      return;
    this.onFly = true;
    let liste = [...this.dusmeSirasiItems];
    let changedElement = liste[evt.oldIndex];
    let dusmeSirasiKey = this.tab == 0 ? 'takip_oncesi_dusme_sirasi' : 'takip_sonrasi_dusme_sirasi';
    changedElement[dusmeSirasiKey] = evt.newIndex + 1;
    liste.splice(evt.oldIndex, 1);
    if (evt.oldIndex > evt.newIndex) {
      for (let i = evt.newIndex; i < liste.length; i++) {
        liste[i][dusmeSirasiKey] = i + 2;
      }
    } else if (evt.oldIndex < evt.newIndex) {
      for (let i = evt.newIndex - 1; i >= 0; i--) {
        liste[i][dusmeSirasiKey] = i + 1;
      }
    }
    liste.push(changedElement);
    liste.sort((a, b) => (a[dusmeSirasiKey] - b[dusmeSirasiKey]));
    if (this.isKartaOzel) {
      this.input();
      this.onFly = false;
    } else {
      this.$http.post('/api/v1/takip-alt-tip-hesap-ayar/dusme-sirasi', liste).then(() => {
        this.$emit('load');
        this.onFly = false;
      });
    }
  }

  input() {
    this.$emit("value", this.items);
    this.$emit("input", this.items);
  }
}
