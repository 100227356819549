
import {Component, Vue, Prop, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {PopUpTitle, PopUpTitleLabel} from "@/enum/PopUpTitle";

@Component({
  components: {},
})
export default class PopUp extends Mixins(ObjectInputMixin) {
  @Prop() TitleType !: PopUpTitle;
  dialog : boolean = true;

  get title(){
    return PopUpTitleLabel[this.TitleType];
  }

  onClose() {
    this.$emit("close")
  }

  onSubmit() {
    this.$emit("success");
    this.onClose();
    }
}
