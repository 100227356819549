
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker},
})
export default class TakipYoluPicker extends Mixins(SimpleInputMixin) {
  @Prop() takipTipiId: any;
  @Prop() returnId: any;
  @Prop() clearable: any;
  @Prop({}) rules!: string;

  get message(): string {
    return this.takipTipiId ? "" : "Lütfen Takip Tipi Seçiniz";
  }

  // TODO: Bütün takip yollarını getirecek istek lazım yoksa rapordaki picker patlar
  get action(): string|undefined {
    if (this.takipTipiId) {
      return "/api/v1/takip-tipi/" + this.takipTipiId + "/takip-yollari";
    }
  }
}
