
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import {BankaHesabiSahibiLabel} from "@/enum/BankaHesabiSahibi";

import TakipTalebiGenerator from "@/classes/evrak/TakipTalebi/TakipTalebiGenerator";
import FormWrapper from "@/components/FormWrapper.vue";
import Evrak from "@/classes/evrak/Evrak";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import KapakHesabi from "@/classes/evrak/KapakHesabi/KapakHesabi";
import KapakHesabiCalculator from "@/classes/evrak/KapakHesabi/KapakHesabiCalculator";
import {TakipDurumu, TakipDurumuLabel} from "@/enum/TakipDurumu";

@Component({
  components: {FormWrapper},
})
export default class IcraTakipKartiTaslak extends Vue {
  @Prop() takip!: TakipEntity;
  @Prop() isKartNoVisible!: boolean;

  kapakHesabi: KapakHesabi = new KapakHesabi();
  ttEvrak: Evrak = new Evrak();
  bankaHesabiSahibiLabel = BankaHesabiSahibiLabel;
  alacakKalemiAlani = AlacakKalemiAlani;
  takipDurumu = TakipDurumuLabel;


  headers = [
    {text: 'Tebliğ Tarihi', value: 'tebligTarihi'},
    {text: 'Mal Beyanı', value: 'malBeyani_check'},
    {text: 'Bila', value: 'bilaTarihi'},
    //{text: 'İkinci Tebligat', value: 'ikinciTebligat_check'},
    {text: 'İtiraz Var', value: 'itirazVar_check'},
    //{text: 'Taah. Kabul Bay. Var.', value: 'taahKabulBayVar_check'},
    {text: 'Kabul Tarihi', value: 'kabulTarihi'},
  ]


  mounted() {
    if (this.takip) {
      this.onTakipChange();
      this.calculateKapakHesabi();
    }
  }

  calculateKapakHesabi() {
    if (this.takip.id) {
      let kapakHesabiCalculator: KapakHesabiCalculator = new KapakHesabiCalculator();
      kapakHesabiCalculator.setTakip(this.takip);
      this.kapakHesabi = kapakHesabiCalculator.calculateKapakHesabi();
      this.kapakHesabi.alacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
      this.kapakHesabi.takipSonrasialacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
    }
  }

  @Watch("takip")
  onTakipChange() {
    let takipTalebiGenerator = new TakipTalebiGenerator()
    takipTalebiGenerator.setTakip(this.takip);
    this.ttEvrak = takipTalebiGenerator.getTakipTalebiEvrak();
  }

  print() {
    this.$helper.printComponent('icraTakipKartiTaslak');
  }
}
