
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import { TakipEntity } from "@/entity/TakipEntity";
import TakipTalebiTaslak from "@/components/TakipTalebiTaslak.vue";
import Emirler from "@/components/Emirler.vue";
import IcraTakipKartiTaslak from "@/components/IcraTakipKartiTaslak.vue";
import JSZip from "jszip";
import { Udf } from "@/classes/HtmlToUdf";
import * as FileSaver from "file-saver";
import OdemeTaahhuduTaslak from "@/components/OdemeTaahhuduTaslak.vue";

@Component({
  components: {
    OdemeTaahhuduTaslak,
    IcraTakipKartiTaslak,
    Emirler,
    TakipTalebiTaslak,
    FormWrapper,
  },
})
export default class OnizleForm extends Vue {
  @Prop() takip!: TakipEntity;
  @Ref("takipTalebiTaslak") readonly takipTalebiTaslak!: TakipTalebiTaslak;
  @Ref("icraTakipKartiTaslak")
  readonly icraTakipKartiTaslak!: IcraTakipKartiTaslak;
  @Ref("odemeTaahhuduTaslak")
  readonly odemeTaahhuduTaslak!: OdemeTaahhuduTaslak;
  @Ref("emirlerTaslak") readonly emirlerTaslak!: Emirler;

  tab = 0;
  isKartNoVisible: boolean = true;
  vekaletSuretHarc : boolean = true

  print() {
    //yazdırda üst ve alttaki tarih/about:blank yazılarını kaldırır
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");
    if (header) {
      header.style.display = "none";
    }
    if (footer) {
      footer.style.display = "none";
    }
    if (this.tab == 0) {
      this.takipTalebiTaslak.print();
    } else if (this.tab == 1) {
      this.emirlerTaslak.print();
    } else if (this.tab == 2) {
      this.icraTakipKartiTaslak.print();
    } else if (this.tab == 3) {
      this.odemeTaahhuduTaslak.print();
    }
  }


  wordYazdir() {
    let belge: string = "";
    if (this.tab == 0) {
      belge = "takipTalebi";
    } else if (this.tab == 1) {
      belge = "emirler";
    } else if (this.tab == 2) {
      belge = "icraTakipKartiTaslak";
    } else if (this.tab == 3) {
      belge = "odemeTaahhuduTaslak";
    }



    var header =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'></head><body>";
    var footer = "</body></html>";
    var sourceHTML = header + (document.getElementById(belge)?.innerHTML) + footer;
    var source =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = belge + ".doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }
}
