
import {Component, Prop, Vue} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import BorcluForm from "@/components/forms/BorcluForm.vue";
import AlacakliCardInfo from "@/components/infos/AlacakliCardInfo.vue";
import TakipAlacakliPicker from "@/components/pickers/TakipAlacakliPicker.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";

@Component({
  components: {FormDialog, TakipAlacakliPicker, AlacakliCardInfo, BorcluForm}
})

export default class AlacakliCardList extends Vue {
  @Prop({required: true}) takipId!: number;
  @Prop() items!: TakipEntity;

  shortView: boolean = true;

  toggleViev() {
    this.shortView = !this.shortView;
  }

  load() {
    this.$emit('load');
  }
}

