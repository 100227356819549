
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ProtestoIhtar from "@/components/forms/ProtestoIhtarForm.vue";
import Dates from "@/components/inputs/Dates.vue";
import TasinirBilgisiForm from "@/components/forms/TasinirBilgisiForm.vue";
import TasinmazBilgisiForm from "@/components/forms/TasinmazBilgisiForm.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {SozlesmeTuru} from "@/enum/SozlesmeTuru";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import AsilAlacakKalemiForm from "@/components/forms/alacak-kalemi/AsilAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import ProtestoIhtarForm from "@/components/forms/ProtestoIhtarForm.vue";
import {TakipTipi} from "@/enum/TakipTipi";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import {PopUpTitle} from "@/enum/PopUpTitle";
import PopUp from "@/components/dialogs/PopUp.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    PopUp,
    DovizTakipSekliPicker,
    Kur,
    StepperValidationForm,
    ProtestoIhtarForm,
    ParaBirimiPicker,
    TasinirBilgisiForm,
    TasinmazBilgisiForm,
    IlamBilgileriForm,
    Dates,
    ProtestoIhtar,
    Tutar,
    AsilAlacakKalemiForm,
    AyEkleForm,
  },
})
export default class IpotekAkitTablosuAlacakFormWizard extends AbstractFormWizard {
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  PopUpTitle = PopUpTitle;
  sozlesmeTuru = SozlesmeTuru;
  paraBirimi = ParaBirimi;
  TakipTipi = TakipTipi;
  dovizTakipSekli = DovizTakipSekli;
  activeStep = 1;
  dialog = false;

  stepperHeaders = [
    "İpotek Akit Tablosu",
    "İhtar",
    "Taşınır/ Taşınmaz Bilgisi",
    "Alacak Kalemi"
  ];
  defaultMahkeme = {
    ilam: 1,
    yargiBirimi: 13,
    dosya: 1
  }

  @Watch('localValue.ihtar_var_mi')
  onIhtarVarMiChange() {
    if (this.localValue.ihtar_var_mi && !this.localValue.ihtarlar) {
      this.localValue.ihtarlar = [];
    } else {
      delete this.localValue.ihtarlar;
    }
  }

  vadeCheck() {
    this.dialog = true;
  }

  stepCheck() {
    this.dialog = false;
    this.nextStep();
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY)
      delete this.localValue.doviz_kur_tarihi;
    this.input();
  }

  mounted() {
    this.$emit('hide-button', true);
    if (!this.localValue.asil_alacaklar) {
      this.localValue.asil_alacaklar = [];
    }
    if (!this.localValue.tasinmazlar && (this.takipTipiId == TakipTipi.ipotekIcraEmri || this.takipTipiId == TakipTipi.ipotekOdemeEmri)) {
      this.localValue.tasinmazlar = [];
    } else {
      this.localValue.tasinirlar = [];
    }
  }
}
