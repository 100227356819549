
import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import Evrak from "@/classes/evrak/Evrak";
import TakipTalebiGenerator from "@/classes/evrak/TakipTalebi/TakipTalebiGenerator";

@Component({})
export default class HesapTablosuInfo extends Vue {
  @Prop() hesapTarihi!: Date
  @Prop() takip!: TakipEntity;
  ttEvrak: Evrak = new Evrak();

  mounted() {
    if (this.takip) {
      this.onTakipChange();
    }
  }

  @Watch("takip")
  onTakipChange() {
    let takipTalebiGenerator = new TakipTalebiGenerator()
    takipTalebiGenerator.setTakip(this.takip);
    this.ttEvrak = takipTalebiGenerator.getTakipTalebiEvrak();
  }
}
