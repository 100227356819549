
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import NafakaAlacakKalemiForm from "@/components/forms/alacak-kalemi/NafakaAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import IlamliNafakaAlacakFormWizard from "@/components/forms/form-wizard/alacak/IlamliNafakaAlacakFormWizard.vue";
import {IlamTipi} from "@/enum/IlamTipi";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {HesapSekli} from "@/enum/HesapSekli";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    IlamliNafakaAlacakFormWizard,
    ParaBirimiPicker,
    Tutar,
    Dates,
    NafakaAlacakKalemiForm,
    ItemArrayManager,
    IlamBilgileriForm,
    AyEkleForm,
  },
})
export default class IlamliNafakaAlacakForm extends Mixins(ObjectInputMixin) {
  @Prop() takipTipiId!: number;
  @Prop() errorAlacakKalemleri!: boolean;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  isWizardComplete: boolean = false;
  mahkemeAdi: string = "";
  ilamTipi = IlamTipi;
  alacakKalemiAlani = AlacakKalemiAlani;

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "alacak_kalemi_turu",
      text: "Alacak Kalemi Türü",
      renderer: (row) => row.alacak_kalemi_turu && row.alacak_kalemi_turu.isim,
    },
    {
      value: "faiz_baslangic_tarihi",
      text: "Faiz B. Tarihi",
      renderer: (row) => this.$helper.tarihStr(row.faiz_baslangic_tarihi),
    },
    {
      value: "takip_oncesi_faiz_turu.isim",
      text: "Takip Öncesi Faiz",
      renderer: (row) =>
          row.elle_faiz_mi ? ('Elle Faiz ' + this.$helper.tutarStr(row.elle_faiz_tutari)) : (row.takip_oncesi_faiz_turu ? row.takip_oncesi_faiz_turu.isim : 'Yasal Faiz'),
    },
    {
      value: "takip_sonrasi_faiz_turu.isim",
      text: "Takip Sonrası Faiz",
      renderer: row => row.takip_sonrasi_faiz_turu ? row.takip_sonrasi_faiz_turu.isim : 'Yasal Faiz',
    },
    {
      value: "tutar",
      text: "Tutar",
      renderer: (row) => this.$helper.tutarStr(row.tutar, row.para_birimi),
    },
  ];

  get defaultAlacakKalemi() {
    let alacakKalemi = new AlacakKalemiEntity();
    alacakKalemi.faiz_hesap_sekli = HesapSekli['365_gun'];
    alacakKalemi.takip_oncesi_faiz_turu_id = this.takipTalebi.takip_alt_tipi.takip_oncesi_faiz_turu_id;
    alacakKalemi.takip_sonrasi_faiz_turu_id = this.takipTalebi.takip_alt_tipi.takip_sonrasi_faiz_turu_id;
    alacakKalemi.is_devam_eden_aylar=false;
    return alacakKalemi;
  }

  get aciklama() {
    if (this.mahkemeAdi && this.mahkemeAdi != "Ara.." && this.localValue.esas_no && this.localValue.karar_tarihi) {
      let kararTarihi = this.$helper.tarihStr(this.localValue.karar_tarihi ?? "");
      return this.mahkemeAdi + " ’nin " + this.localValue.esas_no + "  E. s.lı dosyasında verilen " + kararTarihi + " tarihli kararı";
    }
  }

  @Watch("aciklama")
  onAciklamaChange() {
    this.localValue.aciklama = this.aciklama;
    this.input();
  }

  load() {
    this.$emit('load');
  }
}
