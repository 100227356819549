
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import FormWrapper from "@/components/FormWrapper.vue";
import Faizler from "@/components/forms/Faizler.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import {AlacakBelgeTuru, AlacakBelgeTuruLabel} from "@/enum/AlacakBelgeTuru";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
// import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
@Component({
  components: {
    Kur,
    ParaBirimiPicker,
    FormWrapper,
    Tutar,
    Dates,
    Faizler,
    DovizTakipSekliPicker,
  },
})
export default class AsilAlacakKalemiForm extends Mixins(ObjectInputMixin) {
  @Prop() hideButtons!: boolean | string;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() alacakBelgeTuruId!:number
  @Prop() alacak!: AlacakEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  @Ref('form') readonly form!: FormWrapper;

  readonlyParaBirimi = false;
  paraBirimi = ParaBirimi;
  dovizTakipSekli = DovizTakipSekli;
  AlacakBelgeTuru = AlacakBelgeTuru;

  get dateRule() {
    if (this.alacak.alacak_belge_turu_id == AlacakBelgeTuru.cek) {
      if (this.localValue.ibraz_tarihi) {
        if (new Date(this.alacak.keside_tarihi).getTime() <= new Date(this.localValue.ibraz_tarihi).getTime())
          return [true];
        else
          return ['Keşide Tarihinden daha önce bir ibraz tarihi girilemez.'];
      } else
        return ['Lütfen bu alanı doldurun.'];
    } else if (this.alacak.alacak_belge_turu_id == AlacakBelgeTuru.police) {
      if (this.localValue.vade_tarihi) {
        if (new Date(this.alacak.keside_tarihi).getTime() <= new Date(this.localValue.vade_tarihi).getTime()) {
          return [true];
        } else
          return ['Keşide Tarihinden daha önce bir vade tarihi girilemez.'];
      }
    } else if ([AlacakBelgeTuru.fatura, AlacakBelgeTuru.mts, AlacakBelgeTuru.senet, AlacakBelgeTuru.bono].indexOf(this.alacak.alacak_belge_turu_id) != -1) {
      if (this.localValue.vade_tarihi) {
        if (new Date(this.alacak.tanzim_tarihi).getTime() <= new Date(this.localValue.vade_tarihi).getTime()) {
          return [true];
        } else
          return ['Tanzim Tarihinden daha önce bir vade tarihi girilemez.'];
      }
    } else {
      if (this.localValue.vade_tarihi) {
        return [true];
      }
    }
  }

  @Watch('localValue.para_birimi_id')
  onParaBirimiIdChange() {
    this.addParaBirimi();
  }

  addParaBirimi() {
    if (this.localValue.takip_tarihli_kurlar &&
        this.localValue.para_birimi_id != ParaBirimi.TRY &&
        !this.localValue.takip_tarihli_kurlar.find((kur:any) => kur.para_birimi_id == this.localValue.para_birimi_id)) {
      let kur = new TakipTarihliKurEntity();
      kur.para_birimi = this.localValue.para_birimi;
      kur.para_birimi_id = this.localValue.para_birimi_id;
      kur.tutar = null;
      this.localValue.takip_tarihli_kurlar.push(kur);
    }
  }

  onParaBirimiChange() {
    this.addParaBirimi();
    this.localValue?.asil_alacaklar?.forEach((asilAlacak: AlacakKalemiEntity) => {
      if (this.localValue.para_birimi_id != this.paraBirimi.TRY && this.localValue.doviz_kur_tarihi == DovizTakipSekli.takip) {
        asilAlacak.para_birimi_id = this.localValue.para_birimi_id;
        asilAlacak.para_birimi = this.localValue.para_birimi;
      }
    });
    this.input();
  }
  get minDate() {
    if ((this.alacak.alacak_belge_turu_id === AlacakBelgeTuru.cek || this.alacak.alacak_belge_turu_id == AlacakBelgeTuru.police) && this.alacak.keside_tarihi)
      return this.alacak.keside_tarihi;
    else if ([AlacakBelgeTuru.fatura, AlacakBelgeTuru.mts, AlacakBelgeTuru.senet, AlacakBelgeTuru.bono].indexOf(this.alacak.alacak_belge_turu_id) != -1 && this.alacak.tanzim_tarihi)
      return this.alacak.tanzim_tarihi;
    else
      return null;
  }

  get isHideButtons() {
    return this.hideButtons || this.hideButtons === "";
  }

  get tarih() {
    if (this.localValue.vade_tarihi) {
      return this.localValue.vade_tarihi;
    } else if (this.localValue.ibraz_tarihi) {
      return this.localValue.ibraz_tarihi;
    }
  }

  get aciklama() {
    if (this.alacak.alacak_belge_turu_id && this.localValue.tutar)
      return AlacakBelgeTuruLabel[this.alacak.alacak_belge_turu_id] + " Alacağı";
    else {
      return this.localValue.aciklama;
    }
  }

  get alacakKalemiRules() {
    if (this.localValue.aciklama) {
      if (this.localValue.aciklama.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return true;
  }

  @Watch('tutar')
  onTutarChange() {
    this.localValue.tutar = this.tutar;
    this.input();
  }

  get tutar() {
    if (this.alacak.tutar && !this.alacak.id) {
      if (this.alacak.para_birimi_id != this.paraBirimi.TRY && this.alacak.doviz_kur_tarihi == DovizTakipSekli.vade && this.alacak.kur)
        return Number(this.alacak.tutar) * Number(this.alacak.kur);
      else if (this.alacak.para_birimi_id != this.paraBirimi.TRY && this.alacak.doviz_kur_tarihi == DovizTakipSekli.takip) {
        return Number(this.alacak.tutar) * Number(this.alacak.takip_tarihli_kurlar.find((kur:any) => kur.para_birimi_id == this.alacak.para_birimi_id).tutar ?? 1);
      } else
        return this.alacak.tutar;
    }
    this.localValue.takip_tarihli_kurlar = this.takipTarihliKurlar;

  }

  @Watch('alacak.para_birimi_id')
  onAlacakParaBirimiChange() {
    if (this.alacak.para_birimi_id == this.paraBirimi.TRY) {
      this.readonlyParaBirimi = true;
      this.localValue.para_birimi_id = this.paraBirimi.TRY;
    }
  }

  @Watch('alacak.doviz_kur_tarihi')
  onAlacakDovizKurTarihiChange() {
    if (this.alacak.para_birimi_id != this.paraBirimi.TRY && this.alacak.doviz_kur_tarihi == DovizTakipSekli.vade) {
      this.readonlyParaBirimi = true;
      this.localValue.para_birimi_id = this.paraBirimi.TRY;
    } else if (this.alacak.para_birimi_id != this.paraBirimi.TRY && this.alacak.doviz_kur_tarihi == DovizTakipSekli.tahsilat) {
      this.readonlyParaBirimi = true;
      this.localValue.para_birimi_id = this.alacak.para_birimi_id;
    } else if (this.alacak.para_birimi_id != this.paraBirimi.TRY && this.alacak.doviz_kur_tarihi == DovizTakipSekli.takip) {
      this.readonlyParaBirimi = true;
      this.localValue.para_birimi_id = this.paraBirimi.TRY;
    }
    this.input();
    this.form.setDefaultLocalValue();
  }

  @Watch("tarih")
  onVadeTarihiChange() {
    this.localValue.faiz_baslangic_tarihi = this.tarih;
  }


  @Watch("aciklama")
  onAciklamaChange() {
    this.localValue.aciklama = this.aciklama;
    this.input();
  }

  mounted() {
    this.onAlacakParaBirimiChange();
    this.onAlacakDovizKurTarihiChange();

  }
}
