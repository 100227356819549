
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import OnemPicker from "@/components/pickers/OnemPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Times from "@/components/inputs/Times.vue";
import {EtkinlikEntity} from "@/entity/EtkinlikEntity";
import {OnemDerecesi} from "@/enum/OnemDerecesi";
import HatirlatPicker from "@/components/pickers/HatirlatPicker.vue";
import {HatirlaticiEntity} from "@/entity/HatirlaticiEntity";
import {Hatirlat, HatirlatLabel} from "@/enum/Hatirlat";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import {IsEntity} from "@/entity/IsEntity";

@Component({
  components: {
    HatirlatPicker,
    Times,
    FormWrapper,
    OnemPicker,
    Dates,
  }
})
export default class EtkinlikForm extends Mixins(ObjectInputMixin) {
  @Prop() isBilgisi!: IsEntity;
  @Ref('form') form!: FormWrapper;

  localValue!: EtkinlikEntity;

  get hatirlaticiRules() {
    if (this.localValue.hatirlat) {
      if (this.localValue.start_time)
        return true;
      else
        return 'Başlangıç saati giriniz.';
    } else
      return true;
  }

  mounted() {
    if (this.isBilgisi) {
      this.localValue.start_date = this.isBilgisi.tarih;
      this.localValue.start_time = this.isBilgisi.saat;
      this.localValue.name = "İş - " + this.isBilgisi.isim;
      this.localValue.details = this.isBilgisi.aciklama + " - (İş Numarası: " + this.isBilgisi.id + ")\nDosya Esas No: " + this.isBilgisi.dosya_esas_no + "";
    }
  }

  async save() {
    this.form.onFly = true;
    if (this.localValue.start_time)
      this.localValue.start = this.localValue.start_date + "T" + this.localValue.start_time;
    else
      this.localValue.start = this.localValue.start_date;
    if (this.localValue.end_time)
      this.localValue.end = this.localValue.end_date + "T" + this.localValue.end_time;
    else
      this.localValue.end = this.localValue.end_date;
    if (this.localValue.onem_derecesi == OnemDerecesi.dusuk) this.localValue.color = 'green';
    else if (this.localValue.onem_derecesi == OnemDerecesi.orta) this.localValue.color = 'yellow darken-2';
    else if (this.localValue.onem_derecesi == OnemDerecesi.yuksek) this.localValue.color = 'red';
    else if (this.localValue.onem_derecesi == OnemDerecesi.kritik) this.localValue.color = 'red darken-4';

    let hatirlatici = new HatirlaticiEntity();
    if (this.localValue.hatirlat) {
      hatirlatici.baslik = 'Yaklaşan Etkinlik';
      hatirlatici.aciklama = HatirlatLabel[this.localValue.hatirlat] + ' sonra ' + this.localValue.name + ' başlayacak.';
      let hatirlatmaZamani = new Date((this.localValue.start_date + " " + this.localValue.start_time));
      if (this.localValue.hatirlat == Hatirlat.dakika15) hatirlatmaZamani.setTime(hatirlatmaZamani.getTime() - (15 * 60 * 1000));
      else if (this.localValue.hatirlat == Hatirlat.dakika30) hatirlatmaZamani.setTime(hatirlatmaZamani.getTime() - (30 * 60 * 1000));
      else if (this.localValue.hatirlat == Hatirlat.saat1) hatirlatmaZamani.setTime(hatirlatmaZamani.getTime() - (1 * 60 * 60 * 1000));
      hatirlatici.planlanan_tarih = hatirlatmaZamani.toLocaleString().split(' ')[0];
      hatirlatici.planlanan_saat = hatirlatmaZamani.toLocaleString().split(' ')[1];
      hatirlatici.user_id = this.$store.state.user.id;
    }
    let bildirim = new HatirlaticiEntity();
    bildirim.baslik = 'Etkinlik';
    bildirim.aciklama = this.localValue.name + ' başladı.';
    bildirim.planlanan_tarih = this.localValue.start_date;
    bildirim.planlanan_saat = this.localValue.start_time ? this.localValue.start_time : '00:00';
    bildirim.user_id = this.$store.state.user.id;

    if (this.localValue.id) {
      if (this.localValue.on_hatirlatici_id && this.localValue.hatirlat) {
        await this.$http.put('/api/v1/personel/' + this.$store.state.user.id + '/hatirlatici/' + this.localValue.on_hatirlatici_id, hatirlatici).catch((error) => {
          this.form.onFly = false;
          StatusCodeHelper.error(error);
        });
      } else if (!this.localValue.on_hatirlatici_id && this.localValue.hatirlat) {
        let hatirlaticiResponse = await this.$http.post('/api/v1/personel/' + this.$store.state.user.id + '/hatirlatici', hatirlatici).catch((error) => {
          this.form.onFly = false;
          StatusCodeHelper.error(error);
        });
        this.localValue.on_hatirlatici_id = hatirlaticiResponse.data.id;
      } else if (this.localValue.on_hatirlatici_id && !this.localValue.hatirlat) {
        this.$http.delete('/api/v1/personel/' + this.$store.state.user.id + '/hatirlatici/' + this.localValue.on_hatirlatici_id).catch((error) => {
          this.form.onFly = false;
          StatusCodeHelper.error(error);
        });
        delete this.localValue.on_hatirlatici_id;
      }
      await this.$http.put('/api/v1/personel/' + this.$store.state.user.id + '/hatirlatici/' + this.localValue.hatirlatici_id, bildirim).catch((error) => {
        this.form.onFly = false;
        StatusCodeHelper.error(error);
      });
      await this.$http.put('/api/v1/personel/' + this.$store.state.user.id + '/etkinlik/' + this.localValue.id, this.localValue).catch((error) => {
        this.form.onFly = false;
        StatusCodeHelper.error(error);
      });
      this.form.onFly = false;
      this.$emit('onSuccess')
    } else {
      if (this.localValue.hatirlat) {
        let hatirlaticiResponse = await this.$http.post('/api/v1/personel/' + this.$store.state.user.id + '/hatirlatici', hatirlatici).catch((error) => {
          this.form.onFly = false;
          StatusCodeHelper.error(error);
        });
        this.localValue.on_hatirlatici_id = hatirlaticiResponse.data.id;
      }
      let bildirimResponse = await this.$http.post('/api/v1/personel/' + this.$store.state.user.id + '/hatirlatici', bildirim).catch((error) => {
        this.form.onFly = false;
        StatusCodeHelper.error(error);
      });
      this.localValue.hatirlatici_id = bildirimResponse.data.id;
      await this.$http.post('/api/v1/personel/' + this.$store.state.user.id + '/etkinlik', this.localValue).catch((error) => {
        this.form.onFly = false;
        StatusCodeHelper.error(error);
      });
      this.form.onFly = false;
      this.$emit('onSuccess')
    }
  }
}
