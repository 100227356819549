

import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {AlacakliEntity} from "@/entity/AlacakliEntity";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import NotList from "@/components/lists/NotList.vue";
import {NotEntity} from "@/entity/NotEntity";
import {NotTuru} from "@/enum/NotTuru";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {TakipEntity} from "@/entity/TakipEntity";

@Component({
  components: {NotList, FormDialog, DeleteDialog}
})

export default class AlacakliCardInfo extends Vue {
  @Prop() alacakli!: AlacakliEntity;
  @Prop() takip !: TakipEntity;
  @Ref('notList') readonly notList!: NotList;

  vekalet !: VekaletEntity;
  notTuru = NotTuru
  TakipDurumu = TakipDurumu;
  headers = [
    {text: "Sabit Telefon", value: "deger"},
    {text: "Mobil Telefon", value: "deger"}
  ];

  @Watch('takip.borclular')
  onBorclularChange() {
    this.notList?.onTakipBorclulariChange();
  }

  @Watch('takip.alacaklilar')
  onAlacaklilarChange() {
    this.notList?.onTakipAlacaklilariChange();
  }

  detay(item: AlacakliEntity) {
      this.$router.push(ProgramPaths.ofispro + "/muvekkil/" + item.muvekkil.id);
  }

  load() {
    this.$emit('load')
  }
}
