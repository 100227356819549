

import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker}
})
export default class TakipEtiketiPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean;
  @Prop() disabled: any;
}
