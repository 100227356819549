

import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TahsilHarci, TahsilHarciLabel} from "@/enum/TahsilHarci";

@Component({
  components: {EnumPicker}
})
export default class TahsilHarciPicker extends Mixins(SimpleInputMixin) {
  tahsilHarciEnum = TahsilHarci;
  tahsilHarciLabel = TahsilHarciLabel;
}
