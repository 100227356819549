
import {Component, Prop} from "vue-property-decorator";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "@/components/inputs/Dates.vue";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import TeminatForm from "@/components/forms/TeminatForm.vue";
import BirIsinYapilmasiVeyaYapilmamasiAlacakKalemiForm
  from "@/components/forms/alacak-kalemi/BirIsinYapilmasiVeyaYapilmamasiAlacakKalemiForm.vue";
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {TakipTipi} from "@/enum/TakipTipi";
import PopUp from "@/components/dialogs/PopUp.vue";
import {PopUpTitle} from "@/enum/PopUpTitle";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    PopUp,
    StepperValidationForm,
    BirIsinYapilmasiVeyaYapilmamasiAlacakKalemiForm,
    TeminatForm,
    Dates,
    IlamBilgileriForm,
    Tutar,
  },
})
export default class BirIsinYapilmasiVeyaYapilmamasiAlacakFormWizard extends AbstractFormWizard {
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  activeStep = 1;
  takipTipi=TakipTipi;
  dialog = false;
  PopUpTitle = PopUpTitle;

  stepperHeaders = [
    "Bir İşin Yapılması veya Yapılmaması Alacağı",
    "Teminat",
    "Alacak Kalemi"
  ];

  defaultMahkeme = {
    ilam: 1,
    yargiBirimi: 13,
    dosya: 1
  }

  vadeCheck(){
    this.dialog=true;
  }
  stepCheck(){
    this.dialog=false;
    this.nextStep();
  }

  nextStepRules() {
    if (this.activeStep == 2) {
      if (!this.localValue.teminat_var_mi) {
        if (this.activeStep === this.stepperHeaders.length)
          this.save();
        else
          this.activeStep++;
      }
    }
  }

  previousStepRules() {
    if (this.activeStep == 2) {
      if (!this.localValue.teminat_var_mi) {
        this.activeStep--;
      }
    }
  }

  mounted() {
    this.$emit('hide-button', true);
  }
}
