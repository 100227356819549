
import {Component, Vue, Prop, Ref} from "vue-property-decorator";
import {VForm} from "@/types";

@Component({
  components: {},
})
export default class StepperValidationForm extends Vue {
  @Prop() firstStep !: boolean | string;
  @Ref('form') readonly form!: VForm;

  valid: boolean = false;

  get isFirstStep() {
    return this.firstStep || this.firstStep === "";
  }

  onValidate() {
    this.form.validate();
    let isChildrenValid = this.validateChildStepperForm();
    return this.valid && isChildrenValid
  }

  validateChildStepperForm(component: Vue = this) {
    let valid = true;
    component.$children?.forEach((child: any) => {
      if (child.$options._componentTag == 'stepper-validation-form' || child.$options._componentTag == 'form-wrapper') {
        child.form.validate();
        let isChildValid = child.valid !== undefined ? child.valid : true;
        valid = valid && isChildValid;
      } else if (child.$options.name === "v-data-table" || child.$options.name === "v-card") {
        return true;
      }
      valid = valid && this.validateChildStepperForm(child);
    });
    return valid;
  }

  close() {
    this.$emit('hide-button', false);
    this.$emit('onClose');
  }

  nextStep() {
    if (this.onValidate()) {
      this.$emit('onNext')
    }
  }

  previousStep() {
    this.$emit('onPrevious')
  }
}
