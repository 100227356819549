
import {Prop, Component, Vue, Mixins, Watch} from "vue-property-decorator";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {TakipEntity} from "@/entity/TakipEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import DovizKur from "@/components/inputs/DovizKur.vue";


@Component({
  components: {DovizKur, Kur,Tutar}
})
export default class TaksitAnaBilgiler extends Mixins(ObjectInputMixin) {
  @Prop() takip!: TakipEntity;
  @Prop() genelToplam!: number;
  @Prop({default:false}) feragatVarMi !:boolean
  doviz_kur:Array<number>=[]
  doviz_kur_kontrol:boolean[] =[]
  doviz_checkbox:boolean =false

  tahsilatTurluDoviz:boolean=false

  mounted() {
    this.dovizKurluAlacaklar
  }

  get dovizKurluAlacaklar(){
    let dovizArray :any = []
    dovizArray = this.takip.talepler[0].alacaklar.filter((doviz: any) => doviz.para_birimi_id != 1);

    dovizArray.forEach((doviz: any) => {
     if(doviz.doviz_kur_tarihi==this.dovizTakipSekli.tahsilat){
       this.tahsilatTurluDoviz=true
     }
    })

    return dovizArray;
  }

  handleDoviz(){
    return this.doviz_checkbox = true
  }

  changeDovizTutar(index:number){
    if(this.doviz_kur_kontrol[index]){
      this.doviz_kur_kontrol[index]=false
    }
    this.input()
  }

  @Watch("doviz_kur_kontrol")
  changeDovizKur(){
    this.takip.takip_tarihli_kur.forEach((doviz:any,index) => {
      if (this.takip.talepler[0].alacaklar.find((para:any)=>(para.para_birimi_id==doviz.para_birimi_id))){
          if(this.doviz_kur_kontrol[index] && this.doviz_kur[index]){
            this.$http.put(`/api/v1/takip-tarihli-kur/` + doviz.id, {
              takip_id: this.takip.id,
              id: doviz.id,
              tutar: this.doviz_kur[index],
              para_birimi_id: doviz.para_birimi.id,
            });
          }

      }
    })
    this.$emit("load");
    }

  alacakKalemiAlani = AlacakKalemiAlani;
  dovizTakipSekli = DovizTakipSekli
  get tahsilHarci() {
    let harcToplami = 0;
    if (this.takip) {
      this.takip.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
        takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
          alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
            if (alacakKalemi.alacak_kalemi_alani.id == this.alacakKalemiAlani.harc && alacakKalemi.harc_turu_id == 1) {
              //harc_turu_id == 1 tahsil harcı
              harcToplami += Number(alacakKalemi.tahsil_edilmeyen_tutar);
            }
          });
        });
      });
    }
    return harcToplami;
  }
}
