
import {Component, Mixins, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TakipForm from "@/components/forms/TakipForm.vue";
import Dates from "@/components/inputs/Dates.vue";
import {mask} from "vue-the-mask";
import {EkAlanTuru} from "@/enum/EkAlanTuru";
import {TakipDurumu} from "@/enum/TakipDurumu";

@Component({
  directives: {
    mask
  },
  components: {
    FormDialog,
    TakipForm,
    Dates,
  }
})
export default class TakipInfo extends Mixins(ObjectInputMixin) {
  details = true;
  show: boolean = false;
  EkAlanTuru = EkAlanTuru;
  TakipDurumu = TakipDurumu;

  load() {
    this.$emit('load');
    this.$emit('kapaliToAcik')

  }
}
