
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import TakipAltTipAlanlarList from "@/components/lists/TakipAltTipAlanlarList.vue";
import TahsilatDusmeSirasi from "@/components/TahsilatDusmeSirasi.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import FormWrapper from "@/components/FormWrapper.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {
    FormWrapper,
    TahsilatDusmeSirasi,
    TakipAltTipAlanlarList
  },
})
export default class KartOzelindeHesapAyarForm extends Mixins(SimpleInputMixin) {
  @Prop() takipTalebi!: TakipTalebiEntity;
  @Ref('formWrapper') formWrapper!: FormWrapper;

  save() {
    this.takipTalebi.alt_tip_hesap_ayar.forEach((v: any) => {
      if (v.etiket == "") {
        v.etiket = null;
      }
    })

    this.formWrapper.onFly = true;
    if (this.localValue) {
      return this.$http.put('/api/v1/takip-talebi/' + this.takipTalebi.id, this.takipTalebi).then(() => {
        this.$emit("onClose");
        this.$emit('load')
      }).finally(() => (this.formWrapper.onFly = false));
    }
  }
}
