
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";
import {AlacakBelgeTuruEntity} from "@/entity/AlacakBelgeTuruEntity";

@Component({})
export default class AlacakBelgeTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) takipTipiId!: number;
  @Prop() ilamli!: boolean;
  @Prop({default: false}) edit !: boolean
  @Prop() disabled!: boolean | string;

  items: Array<object> = [];

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  @Watch("takipTipiId")
  onTakipTipiChange() {
    this.load();
  }

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/takip-tipi/" + this.takipTipiId + "/alacak-belge-turleri").then((response) => {
      this.items = response.data.filter((item: AlacakBelgeTuruEntity) => (item.id != AlacakBelgeTuru.itirazinIptaliDavasi));
    });
  }
}
