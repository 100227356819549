
import {Component, Mixins, Prop} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
  components: {
    IlamBilgileriForm,
    TcKimlikNumarasi,
    Tutar,
    Dates,
  },
})
export default class KisiselIliskiAlacakForm extends Mixins(ObjectInputMixin) {
  defaultMahkeme = {
    ilam: 1,
    yargiBirimi: 13,
    dosya: 1
  }
}
