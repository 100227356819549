
import {Component, Mixins, Prop, Ref,} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import HarcHesabiTaslak from "@/components/TakipHarcYokTaslak.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import TakipOncesiTaslak from "@/components/TakipOncesiTaslak.vue";
import TakipHarcYokTaslak from "@/components/TakipHarcYokTaslak.vue";
import HarcHesapTablosuTaslak from "@/components/HarcHesapTablosuTaslak.vue";
import TakipHarcVarTaslak from "@/components/TakipHarcVarTaslak.vue";
import TakipTalebiTaslak from "@/components/TakipTalebiTaslak.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";

@Component({
  components: {
    TakipTalebiTaslak,
    TakipHarcVarTaslak,
    HarcHesapTablosuTaslak,
    TakipHarcYokTaslak,
    TakipOncesiTaslak,
    HarcHesabiTaslak, FormWrapper
  }
})
export default class HesapTablosuForm extends Mixins(ObjectInputMixin) {
  @Prop() takip!: TakipEntity
  @Ref("takipHarcYokTaslak") readonly takipHarcYokTaslak!: TakipHarcYokTaslak;
  @Ref("takipOncesiHesabiTaslak") readonly takipOncesiHesabiTaslak!: TakipOncesiTaslak;
  @Ref("harcHesapTablosuTaslak") readonly harcHesapTablosuTaslak!: HarcHesapTablosuTaslak;
  @Ref("takipHarcVarTaslak") readonly takipHarcVarTaslak!: TakipHarcVarTaslak;

  @Prop() hesapTarihi!: Date

  tab = 0;
  takipOncesiTutar: boolean = false
  harcHesabi: boolean = false

  get dovizTahsilatlar(){
    let tahsilatlar :any = []
    let dovizToplami:number = 0

    this.takip.tahsilatlar.forEach(tah =>{
      tahsilatlar.push(tah)
    })
    tahsilatlar = tahsilatlar.filter(tah => tah.para_birimi_id !== ParaBirimi.TRY);

    tahsilatlar.forEach(doviz => {
      dovizToplami += doviz.tutar * doviz.kur;
    });
    return dovizToplami;
  }

  print() {
    //yazdırda üst ve alttaki tarih/about:blank yazılarını kaldırır
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");
    if (header) {
      header.style.display = "none";
    }
    if (footer) {
      footer.style.display = "none";
    }
    if (!this.takipOncesiTutar && !this.harcHesabi) {
      this.takipHarcYokTaslak.print();
    } else if (this.takipOncesiTutar && !this.harcHesabi) {
      this.takipOncesiHesabiTaslak.print();
    } else if (!this.takipOncesiTutar && this.harcHesabi) {
      this.harcHesapTablosuTaslak.print();
    } else if (this.takipOncesiTutar && this.harcHesabi) {
      this.takipHarcVarTaslak.print();
    }
  }

  async wordYazdir() {
    let belge: string = "";

    if (!this.takipOncesiTutar && !this.harcHesabi) {
      belge = "takipHarcYokTaslak"
    } else if (this.takipOncesiTutar && !this.harcHesabi) {
      belge = "takipOncesiHesabiTaslak"
    } else if (!this.takipOncesiTutar && this.harcHesabi) {
      belge = "harcHesapTablosuTaslak"
    } else if (this.takipOncesiTutar && this.harcHesabi) {
      belge = "takipHarcVarTaslak"
    }
    var header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'></head><body>";
    var footer = "</body></html>";
    var sourceHTML = header + (document.getElementById(belge)?.innerHTML) + footer;
    var source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = belge + ".doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }
}
