
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import FormWrapper from "@/components/FormWrapper.vue";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";

@Component({
  components: {FormWrapper},
})
export default class KapakHesabiAlacakKalemleriInfo extends Vue {
  @Prop() item!: TakipEntity;
  @Prop() alacakKalemAlaniId!: number;

  AlacakKalemiAlani = AlacakKalemiAlani;
  alacakKalemleri: Array<AlacakKalemiEntity> = [];

  mounted() {
    this.load();
  }

  @Watch('item')
  onItemChange() {
    this.load();
  }

  load() {
    this.getir();
  }

  getir() {
    this.alacakKalemleri = [];
    this.item.talepler.forEach((takipTalebi: TakipTalebiEntity) => {
      takipTalebi.alacaklar.forEach((alacak: AlacakEntity) => {
        alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
          if (alacakKalemi.alacak_kalemi_alani.id == this.alacakKalemAlaniId) {
            this.alacakKalemleri.push(alacakKalemi);
          }
        })
      })
    })
  }

  print() {
    this.$helper.printComponent('alacakKalemleri'+this.alacakKalemAlaniId);
  }
}
