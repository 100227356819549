
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import AlacakTuruPicker from "@/components/pickers/AlacakTuruPicker.vue";
import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";
import {ParaBirimi} from "@/enum/ParaBirimi";
import Faizler from "@/components/forms/Faizler.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import Kur from "@/components/inputs/Kur.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";

@Component({
  components: {
    Kur,
    ParaBirimiPicker,
    AlacakTuruPicker,
    Tutar,
    Dates,
    Faizler,
    FormWrapper,
  },
})
export default class IsciAlacakKalemiForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) takipId!: number;
  @Prop() takipTipiId!: number;
  @Prop() alacakBelgeTuruId!: number;
  @Prop({default: false}) hideButtons!: boolean;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;
  @Prop() paraBirimleri!:Array<ParaBirimi>

  AlacakBelgeTuru = AlacakBelgeTuru;
  ParaBirimiTuru = ParaBirimi;
  paraBirimi = ParaBirimi;
  dovizTakipSekli = DovizTakipSekli;
  @Watch("tarih")
  onVadeTarihiChange() {
    this.localValue.faiz_baslangic_tarihi = this.tarih;
  }

  get tarih() {
    if (this.localValue.vade_tarihi) {
      return this.localValue.vade_tarihi;
    } else if (this.localValue.ibraz_tarihi) {
      return this.localValue.ibraz_tarihi;
    }
  }

  addParaBirimi() {
    if (this.localValue.takip_tarihli_kurlar &&
        this.localValue.para_birimi_id != ParaBirimi.TRY &&
        !this.localValue.takip_tarihli_kurlar.find((kur:any) => kur.para_birimi_id == this.localValue.para_birimi_id)) {
      let kur = new TakipTarihliKurEntity();
      kur.para_birimi = this.localValue.para_birimi;
      kur.para_birimi_id = this.localValue.para_birimi_id;
      kur.tutar = null;
      this.localValue.takip_tarihli_kurlar.push(kur);
    }
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY) {
      delete this.localValue.doviz_kur_tarihi;
      this.localValue?.asil_alacaklar?.forEach((asilAlacak: AlacakKalemiEntity) => {
        asilAlacak.para_birimi_id = this.paraBirimi.TRY;
        asilAlacak.para_birimi = this.paraBirimleri.find((paraBirimi: any) => (paraBirimi.id == this.paraBirimi.TRY));
      });
    }
    this.addParaBirimi();
    this.input();
  }

  get alacakKalemiRules() {
    if (this.localValue.aciklama) {
      if (this.localValue.aciklama.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return true;
  }

  @Watch("aciklama")
  onAciklamaChange() {
    this.localValue.aciklama = this.aciklama;
    this.input();
  }

  get aciklama() {
    if (this.localValue.alacak_kalemi_turu) {
      return this.localValue.alacak_kalemi_turu.isim + " Alacağı";
    }
  }
  mounted(){
    this.localValue.takip_tarihli_kurlar = this.takipTarihliKurlar;
  }
}
