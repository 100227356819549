
import {Component, Mixins, Prop} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import TeminatTuruPicker from "@/components/pickers/TeminatTuruPicker.vue";
import TeminatNedeniPicker from "@/components/pickers/TeminatNedeniPicker.vue";

@Component({
  components: {TeminatNedeniPicker, TeminatTuruPicker, Tutar, Dates, NoterPicker, FormWrapper},
})
export default class TeminatForm extends Mixins(ObjectInputMixin) {
  @Prop() hideButtons!: boolean | string;

  get isHideButtons() {
    return this.hideButtons || this.hideButtons === "";
  }
}
