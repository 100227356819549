
import {Component, Prop} from "vue-property-decorator";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import NafakaAlacakKalemiForm from "@/components/forms/alacak-kalemi/NafakaAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import {IlamTipi} from "@/enum/IlamTipi";
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    StepperValidationForm,
    ParaBirimiPicker,
    Tutar,
    Dates,
    NafakaAlacakKalemiForm,
    ItemArrayManager,
    IlamBilgileriForm,
    AyEkleForm,
  },
})
export default class IlamliNafakaAlacakFormWizard extends AbstractFormWizard {
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  activeStep = 1;
  ilamTipi = IlamTipi;

  stepperHeaders = [
    "Nafaka Alacağı",
    "Alacak Kalemi"
  ];
  defaultMahkeme = {
    ilam: 1,
    yargiBirimi: 12,
    dosya: 1
  }
  mounted() {
    this.$emit('hide-button', true);
    if (!this.localValue.asil_alacaklar) {
      this.localValue.asil_alacaklar = [];
    }
  }

  previousStepRules() {
    return true;
  }

  nextStepRules() {
    return true;
  }
}
