
import {Component, Mixins, Prop} from "vue-property-decorator";
import ProtestoIhtar from "@/components/forms/ProtestoIhtarForm.vue";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import TuketiciHakemHeyetiKarariAlacakFormWizard
  from "@/components/forms/form-wizard/alacak/TuketiciHakemHeyetiKarariAlacakFormWizard.vue";
import ProtestoIhtarForm from "@/components/forms/ProtestoIhtarForm.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import KararNumarasi from "@/components/inputs/KararNumarasi.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import AsilAlacakKalemiForm from "@/components/forms/alacak-kalemi/AsilAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {HesapSekli} from "@/enum/HesapSekli";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    DovizTakipSekliPicker,
    IlamBilgileriForm,
    AyEkleForm,
    AsilAlacakKalemiForm,
    KararNumarasi,
    ItemArrayManager,
    ProtestoIhtarForm,
    TuketiciHakemHeyetiKarariAlacakFormWizard, ParaBirimiPicker, Tutar, Dates, ProtestoIhtar
  },
})
export default class TuketiciHakemHeyetiKarariAlacakForm extends Mixins(ObjectInputMixin) {
  @Prop() takipTipi!: number;
  @Prop() takipTipiId!: number;
  @Prop() errorAlacakKalemleri!: boolean;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  isWizardComplete: boolean = false;
  paraBirimi = ParaBirimi;
  dovizTakipSekli = DovizTakipSekli;

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "alacak_kalemi_turu",
      text: "Alacak Kalemi Türü",
      renderer: (row) => row.alacak_kalemi_alani ? row.alacak_kalemi_alani.isim : "Asıl Alacak",
    },
    {
      value: "faiz_baslangic_tarihi",
      text: "Tarih",
      renderer: (row) => this.$helper.tarihStr(row.faiz_baslangic_tarihi),
    },
    {
      value: "takip_oncesi_faiz_turu.isim",
      text: "Takip Öncesi Faiz",
      renderer: (row) =>
          row.elle_faiz_mi ? ('Elle Faiz ' + this.$helper.tutarStr(row.elle_faiz_tutari)) : (row.takip_oncesi_faiz_turu ? row.takip_oncesi_faiz_turu.isim : 'Yasal Faiz'),
    },
    {
      value: "takip_sonrasi_faiz_turu.isim",
      text: "Takip Sonrası Faiz",
      renderer: row => row.takip_sonrasi_faiz_turu ? row.takip_sonrasi_faiz_turu.isim : 'Yasal Faiz',
    },
    {
      value: "tutar",
      text: "Tutar",
      renderer: (row) => this.$helper.tutarStr(row.tutar, row.para_birimi),
    },
  ];

  get defaultAlacakKalemi() {
    let alacakKalemi = new AlacakKalemiEntity();
    alacakKalemi.faiz_hesap_sekli = HesapSekli['365_gun'];
    alacakKalemi.para_birimi_id = this.paraBirimi.TRY;
    alacakKalemi.takip_oncesi_faiz_turu_id = this.takipTalebi.takip_alt_tipi.takip_oncesi_faiz_turu_id;
    alacakKalemi.takip_sonrasi_faiz_turu_id = this.takipTalebi.takip_alt_tipi.takip_sonrasi_faiz_turu_id;
    return alacakKalemi;
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY)
      delete this.localValue.doviz_kur_tarihi;
    this.input();
  }

  load() {
    this.$emit('load');
  }
}
