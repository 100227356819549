export enum PersonelTuru{
    sys_adm= 1,
    kurucu_avukat,
    idari_yonetici,
    avukat ,
    stajyer_avukat ,
    baskatip ,
    katip ,
    muhasebe ,
    sekreter ,
    diger
}
export enum PersonelTuruLabel{
    kurucu_avukat="Kurucu Avukat",
    avukat = "Avukat",
    katip = "Katip",
    stajyer_avukat = "Stajyer Avukat",
    sekreter = "Sekreter",
    muhasebe = "Muhasebe",
    baskatip = "Başkatip",
    idari_yonetici = "İdari Yönetici",
    sys_adm = "Sistem Yöneticisi",
    diger = "Diğer"
}


