
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import TazminatAlacakKalemiForm from "@/components/forms/alacak-kalemi/TazminatAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    IlamBilgileriForm,
    MahkemeSecim,
    AyEkleForm,
    TazminatAlacakKalemiForm,
    ItemArrayManager,
  },
})
export default class IsciAlacakForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) takipId!: number;
  @Prop() takipTipiId!: number;
  @Prop() errorAlacakKalemleri!: boolean;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "alacak_kalemi_turu",
      text: "Alacak Kalemi Türü",
      renderer: (row) => row.alacak_kalemi_turu && row.alacak_kalemi_turu.isim,
    },
    {
      value: "faiz_baslangic_tarihi",
      text: "Faiz B. Tarihi",
      renderer: (row) => this.$helper.tarihStr(row.faiz_baslangic_tarihi),
    },
    {
      value: "takip_oncesi_faiz_turu.isim",
      text: "Takip Öncesi Faiz",
      renderer: (row) =>
          row.elle_faiz_mi ? ('Elle Faiz ' + this.$helper.tutarStr(row.elle_faiz_tutari)) : (row.takip_oncesi_faiz_turu ? row.takip_oncesi_faiz_turu.isim : 'Yasal Faiz'),
    },
    {
      value: "takip_sonrasi_faiz_turu.isim",
      text: "Takip Sonrası Faiz",
      renderer: row => row.takip_sonrasi_faiz_turu ? row.takip_sonrasi_faiz_turu.isim : 'Yasal Faiz',
    },
    {
      value: "tutar",
      text: "Tutar",
      renderer: (row) => this.$helper.tutarStr(row.tutar, row.para_birimi),
    },
  ];

  load() {
    this.$emit('load');
  }
}
