
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DetailDialog extends Vue {
  @Prop() value: any;
  @Prop() title!: string | null;
  @Prop() icon!: string;
  @Prop() label!: string;
  @Prop() color!: string;
  @Prop() dark!: string | boolean;
  @Prop() medium!: string | boolean;
  @Prop() isOutlined!: string | boolean;
  @Prop({ default: "600" }) width!: string | number;

  private buttonColor = this.color ? this.color : "light-blue darken-4";
  private iconStr = this.icon ? this.icon : "mdi-plus-thick";

  show: boolean = false;

  mounted() {
    //debugger;
  }

  get isMedium() {
    return this.medium || this.medium === "";
  }

  get isDark() {
    return this.dark || this.dark === "";
  }

  onClose() {
    this.show = false;
  }
}
