
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import IsForm from "@/components/forms/IsForm.vue";
import {IsEntity} from "@/entity/IsEntity";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import Dates from "@/components/inputs/Dates.vue";
import IsInfo from "@/components/infos/IsInfo.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import EtkinlikForm from "@/components/forms/EtkinlikForm.vue";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";

@Component({
  components: {PersonelPicker, EtkinlikForm, IsInfo, Dates, DosyaEsasNumarasi, IsForm, DeleteDialog, FormDialog}
})
export default class IsList extends Mixins(SimpleInputMixin) {
  @Prop() takip!: TakipEntity;
  @Prop() takipModu!: boolean | string;
  @Ref('isForm') readonly isForm!: IsForm;

  localValue!: Array<IsEntity>;
  showFilter: boolean = false;
  action: string = '/api/v1/is';
  isFiltered = false;
  filters: any = {
    dosyaEsasNo: null,
    isim: null,
    atanan: null,
    baslangicTarihi: null,
    bitisTarihi: null,
    takip: null
  }

  headers = [
    {text: "Durumu", value: "tamamlandi_mi", width: 50},
    {text: "Favori", width: 50, sortable: true, value: "favori_mi"},
    {text: "İş No", width: 50, sortable: true, value: "id"},
    {text: "Tarih", value: "tarih"},
    {text: "İşin Adı", value: "isim"},
    {text: "Kart No", value: "takip_id"},
    {text: "Dosya Numarası", value: "dosya_esas_no"},
    {text: "Atanan Kişi", value: "atanan_isim"},
    {text: "İşlemler", value: "actions", width: 180, align: "center", sortable: false}
  ];

  // Notun da istenilmesi durumunda iş içine notun da gelmesi gerekiyor şuan get ile ayrıyeten alınıyor
  excelHeaders = {
    'Durumu': {
      field: 'tamamlandi_mi',
      callback: (value: boolean) => {
        return value ? 'Tamamlandı' : 'Tamamlanmadı';
      }
    },
    'Favori': {
      field: 'favori_mi',
      callback: (value: boolean) => {
        return value ? 'Evet' : 'Hayır';
      }
    },
    'İş Numarası': 'id',
    'Tarih': {
      field: 'tarih',
      callback: (value: Date) => {
        return this.$helper.tarihStr(value);
      }
    },
    'Saat': 'saat',
    'Ad': 'isim',
    'Kart Numarası': 'takip_id',
    'Açıklama': 'aciklama',
    'Dosya Numarası': 'dosya_esas_no',
    'Atanan Kişi': 'atanan_isim',
    'Atayan Kişi': 'atayan_isim',
  }

  get isTakipModu() {
    return this.takipModu || this.takipModu === "";
  }

  get takipId() {
    return this.takip?.id;
  }

  get gununTarihi() {
    let bugun = new Date();
    return bugun.toLocaleDateString('tr-TR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
  }

  onFavoriChange(item: IsEntity) {
    item.favori_mi = !item.favori_mi;
    this.$http.put("/api/v1/is/" + item.id, item).then(() => {
      this.load();
    });
  }

  onDurumChange(item: IsEntity) {
    item.tamamlandi_mi = !item.tamamlandi_mi;
    this.$http.put("/api/v1/is/" + item.id, item).then(() => {
      this.load();
    });
  }

  mounted() {
    if (this.isTakipModu) {
      // Kart Noyu kaldır
      this.headers.splice(5, 1);
    } else
      this.search();
  }

  search() {
    this.load();
    let action = "/api/v1/is";
    let filters: Array<string> = [];
    if (this.isTakipModu)
      this.filters['takip'] = this.takip.id;
    Object.keys(this.filters).forEach(key => {
      let value = this.filters[key];
      if (value) {
        filters.push(key + "=" + value);
      }
    });
    this.isFiltered = filters.length > 0;
    this.action = action + (filters.length > 0 ? "/filter?" + filters.join('&') : "");

  }

  tamamlananlariSil() {
    this.$http.post('/api/v1/toplu-sil').then(() => (this.load()));
  }

  load() {
    this.$http.get(this.action).then((response) => {
      this.localValue = response.data;
      this.localValue = this.localValue.filter((is:any) => is.takip_id === this.takip.id);
      this.$emit('load');
    });
  }
}
