
import {Component, Mixins, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
  components: {Tutar, Dates, FormWrapper},
})
export default class TaksitDuzenleForm extends Mixins(ObjectInputMixin) {
}
