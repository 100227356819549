
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import {TakipEkAlanEntity} from "@/entity/TakipEkAlanEntity";
import {EkAlanTuru} from "@/enum/EkAlanTuru";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {Dates},
})

export default class TakipEkAlanForm extends Mixins(SimpleInputMixin) {
  localValue!: Array<TakipEkAlanEntity>;
  EkAlanTuru = EkAlanTuru;

}
