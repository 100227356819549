

import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";
import {AxiosResponse} from "axios";
import {AltTahsilatTuruEntity} from "@/entity/AltTahsilatTuruEntity";
import {TahsilatTuru} from "@/enum/TahsilatTuru";

@Component({
  components: {}
})
export default class AltTahsilatTuruPicker extends Mixins(PickerInputMixin) {
  @Prop({default: null}) tahsilatTuru!: TahsilatTuru;
  filteredItems: Array<AltTahsilatTuruEntity> = [];

  mounted() {
    this.load();
  }

  @Watch("tahsilatTuru")
  onTahsilatTuruChange() {
    if (this.tahsilatTuru != null) {
      this.filteredItems = this.items.filter(item => item.tahsilat_turu == this.tahsilatTuru);
    }
  }

  load() {
    this.$http
      .get("/api/v1/alt-tahsilat-turu")
      .then((response: AxiosResponse<AltTahsilatTuruEntity[]>) => {
        this.items = response.data;
        if (this.tahsilatTuru != null) {
          this.filteredItems = this.items.filter(item => item.tahsilat_turu == this.tahsilatTuru);
        }
      });
  }
}
