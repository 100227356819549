import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipYoluEntity} from "@/entity/TakipYoluEntity";
import {TakipEntity} from "@/entity/TakipEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import {TakipAltTipHesapAyarEntity} from "@/entity/TakipAltTipHesapAyarEntity";


export class TakipTalebiEntity {
    id!: number;
    alacaklar: Array<AlacakEntity> = [];
    alt_tip_hesap_ayar!: Array<TakipAltTipHesapAyarEntity>;
    manuel_takip_yolu!: string;
    takipTarihi !: Date;
    takip_alt_tipi!: TakipAltTipiEntity;
    takip_alt_tipi_id!: number;
    takip_id!: number;
    takip_mahiyeti_id!: number;
    takip_tipi: TakipTipiEntity = new TakipTipiEntity();
    takip_tipi_id!: number;
    takip_yolu!: TakipYoluEntity;
    takip_yolu_id!: number|string;
}
