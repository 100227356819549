
import {Component, Prop, Watch} from "vue-property-decorator";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import NafakaAlacakKalemiForm from "@/components/forms/alacak-kalemi/NafakaAlacakKalemiForm.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import {ParaBirimi} from '@/enum/ParaBirimi';
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    DovizTakipSekliPicker,
    Kur,
    StepperValidationForm,
    DosyaEsasNumarasi,
    ParaBirimiPicker,
    Tutar,
    Dates,
    NafakaAlacakKalemiForm,
    AyEkleForm,
    ItemArrayManager,
    MahkemeSecim,
  },
})
export default class IlamsizNafakaAlacakFormWizard extends AbstractFormWizard {
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  activeStep = 1;
  mahkemeAdi: string = "";
  paraBirimi = ParaBirimi;
  dovizTakipSekli = DovizTakipSekli;

  stepperHeaders = [
    "Nafaka Alacağı",
    "Alacak Kalemi"
  ];

  defaultMahkeme = {
    ilam: 1,
    yargiBirimi: 12,
    dosya: 1
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY)
      delete this.localValue.doviz_kur_tarihi;
    this.input();
  }

  mounted() {
    this.$emit('hide-button', true);
    if (!this.localValue.asil_alacaklar) {
      this.localValue.asil_alacaklar = [];
    }
  }

  previousStepRules() {
    return true;
  }

  nextStepRules() {
    return true;
  }
}
