import {TakipEkAlanTuruEntity} from "@/entity/TakipEkAlanTuruEntity";
import {TakipEntity} from "@/entity/TakipEntity";

export class TakipEkAlanEntity {
    id!: number;
    takip_id!: number
    takip!: TakipEntity;
    takip_ek_alan_turu_id!: number;
    takip_ek_alan_turu!: TakipEkAlanTuruEntity;
    deger?: string;
}