
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import FormWrapper from "@/components/FormWrapper.vue";

@Component({
  components: { TcKimlikNumarasi, FormWrapper },
})
export default class MalikiBilgisiForm extends Mixins(ObjectInputMixin) {
}
