
import {Component, Prop, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import AdresTuruPicker from "@/components/pickers/AdresTuruPicker.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import KiraAlacakKalemiForm from "@/components/forms/alacak-kalemi/KiraAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import {SozlesmeTuru} from "@/enum/SozlesmeTuru";
import Tutar from "@/components/inputs/Tutar.vue";
import ProtestoIhtarForm from "@/components/forms/ProtestoIhtarForm.vue";
import {AdresTuru} from "@/enum/AdresTuru";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import {PopUpTitle} from "@/enum/PopUpTitle";
import PopUp from "@/components/dialogs/PopUp.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import {TakipTipi} from "@/enum/TakipTipi";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    PopUp,
    DovizTakipSekliPicker,
    Kur,
    StepperValidationForm,
    ParaBirimiPicker,
    ProtestoIhtarForm,
    KiraAlacakKalemiForm,
    IlcePicker,
    SehirPicker,
    NoterPicker,
    AdresTuruPicker,
    Dates,
    Tutar,
    AyEkleForm,
  },
})
export default class KiraKontratiAlacakFormWizard extends AbstractFormWizard {
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  PopUpTitle = PopUpTitle;
  TakipTipi = TakipTipi;
  activeStep = 1;
  stepperHeaders = [
    "Kira Kontratı",
    "İhtar",
    "Kira Alacak Kalemi"
  ];
  adresTuru = AdresTuru;
  paraBirimi = ParaBirimi;
  sozlesmeTuru = SozlesmeTuru;
  dovizTakipSekli = DovizTakipSekli;
  dialog = false;


  vadeCheck() {
    this.dialog = true;
  }

  stepCheck() {
    this.dialog = false;
    this.nextStep();
  }

  get maxDate() {
    let yesterday = new Date().setDate(new Date().getDate() - 1)
    return new Date(yesterday).toISOString().substr(0, 10);
  }

  get minDate() {
    if (this.localValue.sozlesme_baslangic_tarihi) {
      let yesterday = new Date(this.localValue.sozlesme_baslangic_tarihi).setDate(new Date(this.localValue.sozlesme_baslangic_tarihi).getDate() + 1)
      return new Date(yesterday).toISOString().substr(0, 10);
    } else return null;
  }

  get tarih() {
    if (this.localValue.sozlesme_tarihi) {
      return this.localValue.sozlesme_tarihi;
    }
  }

  @Watch("tarih")
  onVadeTarihiChange() {
    this.localValue.sozlesme_baslangic_tarihi = this.tarih;
  }

  @Watch('localValue.ihtar_var_mi')
  onIhtarVarMiChange() {
    if (this.localValue.ihtar_var_mi && !this.localValue.ihtarlar) {
      this.localValue.ihtarlar = [];
    } else {
      delete this.localValue.ihtarlar;
    }
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY)
      delete this.localValue.doviz_kur_tarihi;
    this.input();
  }

  kontratTarihRule() {
    if (this.localValue.sozlesme_bitis_tarihi) {
      if (new Date(this.localValue.sozlesme_baslangic_tarihi).getTime() < new Date(this.localValue.sozlesme_bitis_tarihi).getTime()) {
        return true;
      } else
        return 'Sözleşme bitiş tarihi, sözleşme başlangıç tarihinden sonra olmalıdır.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  kontratBaslangicTarihRule() {
    if (this.localValue.sozlesme_baslangic_tarihi) {
      if (new Date(this.localValue.sozlesme_baslangic_tarihi).getTime() < new Date().getTime()) {
        return true;
      } else
        return 'Sözleşme giriş tarihi, bugünün tarihiden önce olmalıdır.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  kontratImzaTarihRule() {
    if (this.localValue.sozlesme_tarihi) {
      if (new Date(this.localValue.sozlesme_tarihi).getTime() < new Date().getTime()) {
        return true;
      } else
        return 'Kontrat imza tarihi, bugünün tarihiden önce olmalıdır.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  mounted() {
    this.$emit('hide-button', true);
    if (!this.localValue.asil_alacaklar) {
      this.localValue.asil_alacaklar = [];
    }
  }
}
