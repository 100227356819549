
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MalikiBilgisiForm from "@/components/forms/MalikiBilgisiForm.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import FormWrapper from "@/components/FormWrapper.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";

@Component({
  components: {
    ItemArrayManager,
    MalikiBilgisiForm,
    FormWrapper,
    SehirPicker,
    IlcePicker,
  },
})
export default class TasinmazBilgisiForm extends Mixins(ObjectInputMixin) {
  @Prop() hideButtons!: boolean | string;
  @Prop() tutar!: number;
  @Prop() para_birimi!: ParaBirimiEntity;

  sehirAdi!: string;
  ilceAdi!: string;

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "isim",
      text: "İsim",
    },
    {
      value: "tc_no",
      text: "TC No",
    },
  ];

  get isHideButtons() {
    return this.hideButtons || this.hideButtons === "";
  }

  get aciklama() {
    if (this.localValue.sehir_id && this.localValue.ilce_id && this.localValue.koy_mahalle && this.localValue.ada && this.localValue.parsel) {
      let aciklama = this.sehirAdi + " ili, " + this.ilceAdi + " ilçesi, " + this.localValue.koy_mahalle + " Mahallesinde kain ve tapuya " + this.localValue.ada + " ada, " + this.localValue.parsel + " parselde kayıtlı";
      if (this.localValue.bagimsiz_bolum)
        aciklama += ", " + this.localValue.bagimsiz_bolum;
      aciklama += " taşınmaz üzerinde";
      if (this.tutar && this.para_birimi)
        aciklama += " " + this.$helper.tutarStr(this.tutar, this.para_birimi) + " bedelli 1. ipotek.  TOPLAM İPOTEK LİMİTİ: " + this.$helper.tutarStr(this.tutar, this.para_birimi);
      return aciklama;
    }
  }

  @Watch("aciklama")
  onAciklamaChange() {
    this.localValue.aciklama = this.aciklama;
  }
}
