import {TakipYoluEntity} from "@/entity/TakipYoluEntity";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipMahiyetiEntity} from "@/entity/TakipMahiyetiEntity";

export class TakipTipiEntity {
    id!: number;
    no!: string;
    eski_no!: string;
    aciklama!: string;
    ilamli: boolean = false;
    alt_tipler: Array<TakipAltTipiEntity> = [];
    takip_yollari: Array<TakipYoluEntity> = [];
    takip_mahiyetleri: Array<TakipMahiyetiEntity> = [];
    kesinlesme_suresi !: number;
    odeme_suresi !: number;
    para_alacagi_var !: boolean;
    rehin_mi !: boolean;
    uyap_kod!: string;
    itiraz_suresi!: number;
    icra_etme_suresi!: number;
}

