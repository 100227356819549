
import { Component, Prop, Vue } from "vue-property-decorator";
import { TahsilatEntity } from "@/entity/TahsilatEntity";
import { TakipTalebiEntity } from "@/entity/TakipTalebiEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";

@Component({
  components: {},
})
export default class AlacakKalemiDetayForm extends Vue {
  @Prop() takipTalebi!: TakipTalebiEntity;
  @Prop() alacakId!: string;

  item: AlacakEntity = new AlacakEntity();

  @Prop()
  tahsilatlar!: Array<TahsilatEntity>;

  mounted() {
    this.load();
  }

  load() {
    this.$http.get(`/api/v1/alacak/${this.alacakId}`).then((response) => {
      this.item = response.data;
    });
  }
}
