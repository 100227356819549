
import { Component, Mixins, Prop } from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: { DatabasePicker },
})
export default class TakipAltTipiPicker extends Mixins(SimpleInputMixin) {
  @Prop({ default: null }) takipTipiId!: number;
  @Prop() returnId: any;
  @Prop() rules!: string;
  @Prop() disabled!: string | boolean;

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  get action() {
    if (this.takipTipiId !== null) {
      return "/api/v1/takip-tipi/" + this.takipTipiId + "/alt-tipleri/";
    }
  }
}
