
import {Component, Prop, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import AsilAlacakKalemiForm from "@/components/forms/alacak-kalemi/AsilAlacakKalemiForm.vue";
import ProtestoIhtarForm from "@/components/forms/ProtestoIhtarForm.vue";
import {TakipTipi} from "@/enum/TakipTipi";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import {PopUpTitle} from "@/enum/PopUpTitle";
import PopUp from "@/components/dialogs/PopUp.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    PopUp,
    DovizTakipSekliPicker,
    Kur,
    StepperValidationForm,
    ProtestoIhtarForm,
    ParaBirimiPicker,
    Tutar,
    SehirPicker,
    Dates,
    AsilAlacakKalemiForm,
  },
})
export default class PoliceAlacakFormWizard extends AbstractFormWizard {
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  PopUpTitle = PopUpTitle;
  activeStep = 1;
  stepperHeaders= [
      "Poliçe",
      "İhtar",
      "Alacak Kalemi"
  ];
  TakipTipi = TakipTipi;
  paraBirimi = ParaBirimi;
  dovizTakipSekli = DovizTakipSekli;
  dialog = false;

  get ihtarTuru(): string {
    if (this.takipTipiId && this.takipTipiId == TakipTipi.kambiyoSenetleriyleIflas || this.takipTipiId == TakipTipi.kambiyoSenetleriyleHaciz) {
      this.stepperHeaders = [
        "Poliçe",
        "Protesto",
        "Alacak Kalemi"
      ];
      return "protesto";
    }
    this.stepperHeaders = [
      "Poliçe",
      "İhtar",
      "Alacak Kalemi"
    ];
    return "ihtar";
  }

  vadeCheck() {
    this.dialog = true;
  }

  stepCheck() {
    this.dialog = false;
    this.nextStep();
  }

  @Watch('localValue.protesto_var_mi')
  onProtestoVarMiChange() {
    if (this.localValue.protesto_var_mi) {
      if (!this.localValue.protestolar && this.ihtarTuru == "protesto") {
        this.localValue.protestolar = [];
      }
    } else {
      delete this.localValue.protestolar;
    }
  }

  @Watch('localValue.ihtar_var_mi')
  onIhtarVarMiChange() {
    if (this.localValue.ihtar_var_mi) {
      if (!this.localValue.ihtarlar && this.ihtarTuru == "ihtar") {
        this.localValue.ihtarlar = [];
      }
    } else {
      delete this.localValue.ihtarlar;
    }
  }

  @Watch("localValue.kesideci")
  onKesideciChange() {
    this.localValue.odenecek_kisi = this.localValue.kesideci;
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY)
      delete this.localValue.doviz_kur_tarihi;
    this.input();
  }

  mounted() {
    this.$emit('hide-button', true);
    if (!this.localValue.asil_alacaklar) {
      this.localValue.asil_alacaklar = [];
    }
  }
}
