
import {Component, Prop, Mixins} from "vue-property-decorator";
import IrtifakVeyaIntifaHakkininKaldirilmasiAlacakKalemiForm
  from "@/components/forms/alacak-kalemi/IrtifakVeyaIntifaHakkininKaldirilmasiAlacakKalemiForm.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import PopUp from "@/components/dialogs/PopUp.vue";
import {PopUpTitle} from "@/enum/PopUpTitle";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";


@Component({
  components: {
    PopUp,
    StepperValidationForm,
    IlamBilgileriForm,
    Tutar,
    ItemArrayManager,
    IrtifakVeyaIntifaHakkininKaldirilmasiAlacakKalemiForm,
  },
})
export default class IrtifakVeyaIntifaHakkininKaldirilmasiAlacakFormWizard extends AbstractFormWizard {
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  activeStep = 1;
  dialog = false;
  PopUpTitle = PopUpTitle;

  stepperHeaders = [
    "İrtifak veya İntifa Hakkının Kaldırılması Alacağı",
    "Alacak Kalemi"
  ];
  defaultMahkeme = {
    ilam: 1,
    yargiBirimi: 13,
    dosya: 1
  }

  vadeCheck(){
    this.dialog=true;
  }
  stepCheck(){
    this.dialog=false;
    this.nextStep();
  }

  mounted() {
    this.$emit('hide-button', true);
    if (!this.localValue.asil_alacaklar) {
      this.localValue.asil_alacaklar = [];
    }
  }

  previousStepRules() {
    return true;
  }

  nextStepRules() {
    return true;
  }
}
