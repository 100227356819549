
import {Component, Mixins, Prop} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IsEntity} from "@/entity/IsEntity";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {NotEntity} from "@/entity/NotEntity";

@Component({
  components: {FormWrapper}
})
export default class IsInfo extends Mixins(SimpleInputMixin) {
  @Prop() takipModu!: boolean | string;

  localValue!: IsEntity;
  eklenenNot: NotEntity = new NotEntity();
  takipDurumu = TakipDurumu;
  printMode = false;

  get isTakipModu() {
    return this.takipModu || this.takipModu === "";
  }

  mounted() {
    if (this.localValue.not_id) {
      this.$http.get('/api/v1/not/' + this.localValue.not_id).then(response => {
        this.eklenenNot = response.data;
      })
    }
  }

  takibeGit() {
    let url = ProgramPaths.icrapro + (this.localValue.takip_durumu != this.takipDurumu.acilmamis ? '/takip/' : '/takip-ekle/') + this.localValue.takip_id;
    this.$router.push(url)
  }

  print() {
    this.printMode = true;
    this.$nextTick(() => {
      this.$helper.printComponent('isInfo' + this.localValue.id);
      this.printMode = false;
    });
  }
}
