
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import DosyaBorcluEgmPicker from "@/components/pickers/uyap/DosyaBorcluEgmPicker.vue";
import HacizSerhiPicker from "@/components/pickers/uyap/HacizSerhiPicker.vue";
import {EGMTalep} from "@/interface/IUyapTalep";

@Component({
  components: {HacizSerhiPicker, DosyaBorcluEgmPicker}
})
export default class EgmTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() dosyaId!: number;
  @Prop() kisiKurumId!: number;
  localValue!: EGMTalep;
}
