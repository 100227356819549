
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker},
})
export default class TeminatNedeniPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean | string;
  @Prop() required!: boolean;
  @Prop() rules!: string;
  @Prop() color: any;
}
