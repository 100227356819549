
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TasinirBilgisiForm from "@/components/forms/TasinirBilgisiForm.vue";
import TasinmazBilgisiForm from "@/components/forms/TasinmazBilgisiForm.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {SozlesmeTuru} from "@/enum/SozlesmeTuru";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import AsilAlacakKalemiForm from "@/components/forms/alacak-kalemi/AsilAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import IpotekAkitTablosuAlacakFormWizard
  from "@/components/forms/form-wizard/alacak/IpotekAkitTablosuAlacakFormWizard.vue";
import {TakipTipi} from "@/enum/TakipTipi";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import ProtestoIhtarForm from "@/components/forms/ProtestoIhtarForm.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {HesapSekli} from "@/enum/HesapSekli";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    ProtestoIhtarForm,
    DovizTakipSekliPicker,
    Kur,
    IpotekAkitTablosuAlacakFormWizard,
    ParaBirimiPicker,
    TasinirBilgisiForm,
    TasinmazBilgisiForm,
    IlamBilgileriForm,
    ItemArrayManager,
    Dates,
    Tutar,
    AsilAlacakKalemiForm,
    AyEkleForm,
  },
})
export default class IpotekAkitTablosuAlacakForm extends Mixins(ObjectInputMixin) {
  @Prop() takipTipiId!: number;
  @Prop() errorAlacakKalemleri!: boolean;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  isWizardComplete: boolean = false;
  paraBirimleri = [];
  TakipTipi = TakipTipi;
  paraBirimi = ParaBirimi;
  sozlesmeTuru = SozlesmeTuru;
  dovizTakipSekli = DovizTakipSekli;
  alacakKalemiAlani = AlacakKalemiAlani;

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "alacak_kalemi_turu",
      text: "Alacak Kalemi Türü",
      renderer: (row) =>
          row.alacak_kalemi_alani ? row.alacak_kalemi_alani.isim : "Asıl Alacak",
    },
    {
      value: "faiz_baslangic_tarihi",
      text: "Faiz B. Tarihi",
      renderer: (row) => this.$helper.tarihStr(row.faiz_baslangic_tarihi),
    },
    {
      value: "takip_oncesi_faiz_turu.isim",
      text: "Takip Öncesi Faiz",
      renderer: (row) =>
          row.elle_faiz_mi ? ('Elle Faiz ' + this.$helper.tutarStr(row.elle_faiz_tutari)) : (row.takip_oncesi_faiz_turu ? row.takip_oncesi_faiz_turu.isim : 'Yasal Faiz'),
    },
    {
      value: "takip_sonrasi_faiz_turu.isim",
      text: "Takip Sonrası Faiz",
      renderer: row => row.takip_sonrasi_faiz_turu ? row.takip_sonrasi_faiz_turu.isim : 'Yasal Faiz',
    },
    {
      value: "tutar",
      text: "Tutar",
      renderer: (row) => this.$helper.tutarStr(row.tutar, row.para_birimi),
    },
  ];

  tasinirHeaders: Array<ItemArrayManagerTableHeader> = [
    {
      value: "tur",
      text: "Taşınır Türü",
    },
    {
      value: "aciklama",
      text: "Açıklama",
    },
  ];

  tasinmazHeaders: Array<ItemArrayManagerTableHeader> = [
    {
      value: "ada",
      text: "Ada",
    },
    {
      value: "pafta",
      text: "Pafta",
    },
    {
      value: "parsel",
      text: "Parsel",
    },
  ];

  ihtarHeaders: Array<ItemArrayManagerTableHeader> = [
    {
      value: "tarih",
      text: "Tarih",
      renderer: (row) => this.$helper.tarihStr(row.tarih),
    },

    {
      value: "tutar",
      text: "Tutar",
      renderer: (row) => this.$helper.tutarStr(row.tutar, row.para_birimi),
    },

    {
      value: "noter",
      text: "Noter",
      renderer: (row) => (row.noter ? row.noter.isim : null),
    },
  ];

  get defaultAlacakKalemi() {
    let alacakKalemi = new AlacakKalemiEntity();
    alacakKalemi.faiz_hesap_sekli = HesapSekli['365_gun'];
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY) {
      alacakKalemi.para_birimi_id = this.paraBirimi.TRY;
      alacakKalemi.para_birimi = this.paraBirimleri.find((paraBirimi: any) => (paraBirimi.id == this.paraBirimi.TRY));
    } else if (this.localValue.para_birimi_id != this.paraBirimi.TRY && this.localValue.doviz_kur_tarihi == DovizTakipSekli.vade) {
      alacakKalemi.para_birimi_id = this.paraBirimi.TRY;
      alacakKalemi.para_birimi = this.paraBirimleri.find((paraBirimi: any) => (paraBirimi.id == this.paraBirimi.TRY));
    } else if (this.localValue.para_birimi_id != this.paraBirimi.TRY && this.localValue.doviz_kur_tarihi == DovizTakipSekli.tahsilat) {
      alacakKalemi.para_birimi_id = this.localValue.para_birimi_id;
      alacakKalemi.para_birimi = this.localValue.para_birimi;
    }
    alacakKalemi.takip_oncesi_faiz_turu_id = this.takipTalebi.takip_alt_tipi.takip_oncesi_faiz_turu_id
    alacakKalemi.takip_sonrasi_faiz_turu_id = this.takipTalebi.takip_alt_tipi.takip_sonrasi_faiz_turu_id
    return alacakKalemi;
  }

  get aciklama() {
    if (this.localValue.yevmiye_no && this.localValue.belge_tarihi && this.localValue.tutar) {
      let belgeTarihi = this.$helper.tarihStr(this.localValue.belge_tarihi ?? "");
      return this.localValue.yevmiye_no + " yevmiye no’lu ve " + belgeTarihi +
          " tarihli ipotek belgesi ve akit tablosu";
    }
  }

  @Watch("aciklama")
  onAciklamaChange() {
    this.localValue.aciklama = this.aciklama;
    this.input();
  }

  @Watch('localValue.doviz_kur_tarihi')
  onDovizTakipSekliChange() {
    this.addParaBirimi();
    this.localValue.asil_alacaklar.forEach((asilAlacak: AlacakKalemiEntity) => {
      if (this.localValue.para_birimi_id != this.paraBirimi.TRY && this.localValue.doviz_kur_tarihi == DovizTakipSekli.vade) {
        asilAlacak.para_birimi_id = this.paraBirimi.TRY;
        asilAlacak.para_birimi = this.paraBirimleri.find((paraBirimi: any) => (paraBirimi.id == this.paraBirimi.TRY));
      } else if (this.localValue.para_birimi_id != this.paraBirimi.TRY && this.localValue.doviz_kur_tarihi == DovizTakipSekli.tahsilat) {
        asilAlacak.para_birimi_id = this.localValue.para_birimi_id;
        asilAlacak.para_birimi = this.localValue.para_birimi;
      }
    });
    this.input();
  }

  @Watch('localValue.para_birimi_id')
  onParaBirimiIdChange() {
    this.addParaBirimi();
  }

  @Watch('localValue')
  onLocalValueChange() {
    if (!this.localValue.takip_tarihli_kurlar) {
      this.localValue.takip_tarihli_kurlar = this.takipTarihliKurlar;
    }
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY) {
      delete this.localValue.doviz_kur_tarihi;
      this.localValue.asil_alacaklar.forEach((asilAlacak: AlacakKalemiEntity) => {
        asilAlacak.para_birimi_id = this.paraBirimi.TRY;
        asilAlacak.para_birimi = this.paraBirimleri.find((paraBirimi: any) => (paraBirimi.id == this.paraBirimi.TRY));
      });
    } else if (this.localValue.para_birimi_id != this.paraBirimi.TRY && this.localValue.doviz_kur_tarihi == DovizTakipSekli.tahsilat) {
      this.localValue.asil_alacaklar.forEach((asilAlacak: AlacakKalemiEntity) => {
        asilAlacak.para_birimi_id = this.localValue.para_birimi_id;
        asilAlacak.para_birimi = this.localValue.para_birimi;
      });
    }
    this.addParaBirimi();
    this.input();
  }

  mounted() {
    this.localValue.takip_tarihli_kurlar = this.takipTarihliKurlar;
    this.input();
  }

  load() {
    this.$emit('load');
  }

  addParaBirimi() {
    if (this.localValue.para_birimi_id != ParaBirimi.TRY &&
        !this.localValue.takip_tarihli_kurlar.find((kur:any) => kur.para_birimi_id == this.localValue.para_birimi_id)) {
      let kur = new TakipTarihliKurEntity();
      kur.para_birimi = this.localValue.para_birimi;
      kur.para_birimi_id = this.localValue.para_birimi_id;
      kur.tutar = null;
      this.localValue.takip_tarihli_kurlar.push(kur);
    }
  }
}
