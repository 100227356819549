
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import TakipTalebiForm from "@/components/forms/TakipTalebiForm.vue";
import IcraDairesiPicker from "@/components/pickers/IcraDairesiPicker.vue";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import Dates from "@/components/inputs/Dates.vue";
import TakipDurumuPicker from "@/components/pickers/TakipDurumuPicker.vue";
import TakipEtiketiPicker from "@/components/pickers/TakipEtiketiPicker.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {TakipEkAlanEntity} from "@/entity/TakipEkAlanEntity";
import {AxiosResponse} from "axios";
import {TakipEkAlanTuruEntity} from "@/entity/TakipEkAlanTuruEntity";
import TakipEkAlanForm from "@/components/forms/TakipEkAlanForm.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TakipTipi} from "@/enum/TakipTipi";


@Component({
  components: {
    TakipEkAlanForm,
    FormWrapper,
    TakipTalebiForm,
    IcraDairesiPicker,
    DosyaEsasNumarasi,
    Dates,
    TakipEtiketiPicker,
    TakipDurumuPicker,
  },
})
export default class TakipForm extends Mixins(ObjectInputMixin) {
  @Prop() isIkili!: boolean;
  @Prop() hideCloseButton!: boolean;
  @Prop() infoMode!: boolean | string;
  @Prop() disabled!: boolean | string;

  localValue!: TakipEntity;
  TakipDurumu = TakipDurumu;
  label = "Dosya Esas Numarası";
  dialog = false;
  change = false;
  aktifTakipDurumu!: TakipDurumu;
  takipTipi = TakipTipi;

  get takipTipiSecilebilsinMi() {
    return this.localValue && this.localValue.alacaklilar.length > 0 && this.localValue.borclular.length > 0 &&
        this.localValue.talepler[0].alacaklar.length > 0;
  }

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  mounted() {
    this.change = false;
    this.onTakipChange();
    this.loadTakipEkAlanlari();
    if (this.localValue.talepler.length === 0) {
      this.localValue.talepler.push(new TakipTalebiEntity());
      this.localValue.talepler.push(new TakipTalebiEntity());
    }
  }

  @Watch("localValue.takip_durumu")
  takipDurumuDialog(){
    /*console.log(this.aktifTakipDurumu,'Onceki')
    console.log(this.localValue.takip_durumu,'Şuanki')*/
  }

  @Watch('localValue.dosya_esas_no')
  onDosyaEsasNoChange() {
    if (this.localValue.dosya_esas_no != '' && this.localValue.dosya_esas_no != null)
      this.localValue.mts_takibi = false;
  }

  @Watch('localValue.icra_dairesi_id')
  onIcraDairesiChange() {
    if (!this.localValue.icra_dairesi_id) {
      this.localValue.icra_dairesi_id = null;
    }
  }

  @Watch('localValue.id')
  onTakipChange() {
    this.aktifTakipDurumuKaydet();

  }

  aktifTakipDurumuKaydet() {
    this.aktifTakipDurumu = this.localValue.takip_durumu;

  }

  loadTakipEkAlanlari() {
    this.$http.get("/api/v1/takip-ek-alan-turu").then((response: AxiosResponse<Array<TakipEkAlanTuruEntity>>) => {
      response.data.forEach((takipEkAlanTuru: TakipEkAlanTuruEntity) => {
        let exists = false;
        this.localValue.takip_ek_alanlari.forEach((takipEkAlan: TakipEkAlanEntity) => {
          if (takipEkAlan.takip_ek_alan_turu_id == takipEkAlanTuru.id) {
            exists = true;
          }
        });

        if (!exists) {
          let takipEkAlan = new TakipEkAlanEntity();
          takipEkAlan.takip_ek_alan_turu_id = takipEkAlanTuru.id;
          takipEkAlan.takip_ek_alan_turu = takipEkAlanTuru;
          takipEkAlan.deger = undefined;
          this.localValue.takip_ek_alanlari.push(takipEkAlan);
          this.input();
        }
      });
    });
  }

  onHesapAktiflestir() {
    this.dialog = false;
    if (this.localValue.takip_durumu === TakipDurumu.acik) {
      this.$router.push(ProgramPaths.icrapro + "/takip/" + this.localValue.id);
    }
    this.$emit('kapaliToAcik')
  }

  @Watch("isIkili")
  onIsIkiliChange() {
    if (this.isIkili && this.localValue.talepler.length == 1) {
      this.localValue.talepler.push(new TakipTalebiEntity());
    }
  }


  get dovizVarMi() {
    let doviz = false;
    if (this.localValue?.talepler[0]?.alacaklar) {
      this.localValue.talepler[0].alacaklar.forEach((alacak: AlacakEntity) => {
        if (alacak.alacak_kalemleri) {
          alacak.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
            if (alacakKalemi.para_birimi_id && alacakKalemi.para_birimi_id !== ParaBirimi.TRY) {
              doviz = true;
            }
          })
        }
      })
    }
    return doviz;
  }

  protected onValueChange() {
    if (this.localValue.talepler.length === 0) {
      this.localValue.talepler.push(new TakipTalebiEntity());
    }
  }

  onTakipDurumuChange() {
    this.localValue.etiketler = [];
    if (this.localValue.takip_durumu === TakipDurumu.kapali)
      this.localValue.kapanis_tarihi = new Date();
    this.input()
  }

  async onUpsertTakip(takip: TakipEntity) {
    this.localValue.id = takip.id;
    let talepler = this.localValue.talepler;

    if (this.localValue.id) {
      await this.saveTakipEkAlan(this.localValue.id, this.localValue.takip_ek_alanlari);
      await this.upsertTakipTalebi(this.localValue.id, talepler[0], talepler[0].takip_id != null);
      if (this.isIkili) {
        await this.upsertTakipTalebi(this.localValue.id, talepler[1], talepler[1].takip_id != null);
      } else if (talepler[1] && talepler[1].id) {
        await this.deleteTakipTalebi(talepler[1]);
      }

      if ((this.localValue.takip_durumu === TakipDurumu.acik && this.$route.path == (ProgramPaths.icrapro + "/takip/" + this.localValue.id)) ||
          (this.localValue.takip_durumu === TakipDurumu.kapali && this.$route.path == (ProgramPaths.icrapro + "/takip/" + this.localValue.id)) ||
          (this.localValue.takip_durumu === TakipDurumu.acilmamis && this.$route.path == (ProgramPaths.icrapro + "/takip-ekle/" + this.localValue.id)))
        this.$emit('onSuccess');
      else if (this.localValue.takip_durumu === TakipDurumu.acilmamis) {
        this.$router.push(ProgramPaths.icrapro + "/takip-ekle/" + this.localValue.id);
      } else if (this.aktifTakipDurumu===TakipDurumu.acilmamis && this.localValue.takip_durumu===TakipDurumu.acik){
        this.$router.push(ProgramPaths.icrapro + "/takip/" + this.localValue.id);
      }else if (this.aktifTakipDurumu===TakipDurumu.acilmamis && this.localValue.takip_durumu===TakipDurumu.kapali){
        this.$router.push(ProgramPaths.icrapro + "/takip/" + this.localValue.id);
      }
    }
    if (this.aktifTakipDurumu===TakipDurumu.kapali && this.localValue.takip_durumu===TakipDurumu.acik){
      this.dialog=true
    }
    this.$emit('takipChanged', this.aktifTakipDurumu,this.localValue.takip_durumu)
  }


  async saveTakipEkAlan(takipId: number, takip_ek_alanlari: Array<TakipEkAlanEntity>) {
    takip_ek_alanlari.forEach((takipEkAlani: TakipEkAlanEntity) => {
      if (takipEkAlani.id) {
        return this.$http.put('/api/v1/takip-ek-alani/' + takipEkAlani.id, takipEkAlani);
      } else {
        return this.$http.post('/api/v1/takip/' + takipId + '/takip-ek-alani/', takipEkAlani);
      }
    });
  }

  async upsertTakipTalebi(takipId: number, takipTalebi: TakipTalebiEntity, isUpdate: boolean = false) {
    takipTalebi.takip_id = takipId;
    if (isUpdate) {
      return this.$http.put("/api/v1/takip-talebi/" + takipTalebi.id, takipTalebi);
    } else {
      return this.$http.post("/api/v1/takip-talebi", takipTalebi);
    }
  }

  async deleteTakipTalebi(takipTalebi: TakipTalebiEntity) {
    return this.$http.delete("/api/v1/takip-talebi/" + takipTalebi.id);
  }
}
