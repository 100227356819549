export enum TaksitlendirmeTuru{
    taksitSayisi,
    taksitTutari,
    ozelTaksitlendirme,
}

export enum TaksitlendirmeTuruLabel{
    "Taksit Sayısına Göre"=0,
    "Taksit Tutarına Göre"=1,
    "Özel Taksitlendirme"=2,
}
