

import {Component, Mixins, Prop} from "vue-property-decorator";

import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlamTipi, IlamTipiLabel} from "@/enum/IlamTipi";

@Component({
  components: {EnumPicker}
})
export default class IlamTipiPicker extends Mixins(SimpleInputMixin) {
  @Prop() defaultFirst !: string | boolean;
  @Prop() rules !: string;

  ilamTipi = IlamTipi
  ilamTipiLabel = IlamTipiLabel;

  get isDefaultFirst(){
    return this.defaultFirst || this.defaultFirst === "";
  }
}
