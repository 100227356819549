
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TaksitHesapAyarEntity} from "@/entity/TaksitHesapAyarEntity";
import TaksitDuzenleForm from "@/components/anakart/taksitlendirme/TaksitDuzenleForm.vue";
import OdemeTaahhuduTaslak from "@/components/OdemeTaahhuduTaslak.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TaksitEntity} from "@/entity/TaksitEntity";

@Component({
  components: {DeleteDialog, FormDialog, OdemeTaahhuduTaslak, TaksitDuzenleForm, Tutar, Dates}
})
export default class OzelTaksitlendirme extends Mixins(ObjectInputMixin) {
  @Prop() esasBorc!: number;
  @Prop({default: false}) disabledHesaplamaButton!: boolean;

  localValue!: TaksitHesapAyarEntity;
  isError: boolean = false;
  hataMesaji = "Hesaplamada hata oluştu.";
  periyodlar = [
    {text: "Ay", value: "ay"},
    {text: "Hafta", value: "hafta"}
  ]
  taksitler:any=[]
  sayisinaGoreHesapla:number=0
  tutarinaGoreHesapla:number=1

  mounted() {
    this.localValue.hesaplansin_mi = false;
  }

  clickSayisinaGore(){
    this.$emit('changeTabs',this.sayisinaGoreHesapla)
  }
  clickTutarinaGore(){
    this.$emit('changeTabs',this.tutarinaGoreHesapla)
  }

  faizVarMiChange() {
    if (this.localValue.faiz_var_mi) {
      this.localValue.hesaplansin_mi = true;
    } else
      this.localValue.hesaplansin_mi = false;
    this.input()
  }

  inputKontrol() {
    if (!this.localValue.taksit_baslangic_tarihi) {
      this.isError = true;
      this.hataMesaji = "Başlangıç tarihini giriniz.";
      return false;
    }
    if (!this.localValue.taksit_tutari) {
      this.isError = true;
      this.hataMesaji = "Taksit tutarını giriniz.";
      return false;
    }
    if (this.localValue.taksit_tutari && this.esasBorc < this.localValue.taksit_tutari) {
      this.isError = true;
      this.hataMesaji = "Taksit tutarı toplam borçtan büyük olamaz.";
      return false;
    }
    this.isError = false;
    return true;
  }

  load() {
    if (this.inputKontrol()) {
      this.$emit('success');
    }
  }

  taksitEkle() {
    if (this.inputKontrol()) {
      this.localValue.hesaplansin_mi = false;
      this.input();
      this.$emit('success');
    }
  }
  @Prop() loading!: boolean;
  @Prop() takip!: TakipEntity;

  @Ref("odemeTaahhuduTaslak")
  readonly odemeTaahhuduTaslak!: OdemeTaahhuduTaslak;

  dialog = false;
  printMode = false;
  multipleDelete = false;
  isKartNoVisible: boolean = true;
  secilenTaksitler = [];
  headers = [
    {text: "Tarih", value: "taksit_baslangic_tarihi"},
    {text: "Taksit Tutarı", value: "taksit_tutari", sortable: false},
    {text: "Faiz İşleyen Gün", value: "faiz_isleyen_gun", sortable: false},
    {text: "İşleyen Faiz", value: "isleyen_faiz", sortable: false},
    {text: "Toplam Faiz", value: "toplam_faiz", sortable: false},
    {text: "Kalan Ana Para", value: "kalan_ana_para", sortable: false},
    {text: "Kalan Faiz", value: "kalan_faiz", sortable: false},
    {text: "Kalan Borc", value: "kalan_borc", sortable: false},
    {text: "İşlemler", value: "actions", width: 100, align: 'center', sortable: false}
  ]

}
