import {TakipEntity} from "@/entity/TakipEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import {forEach} from "jszip";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import any = jasmine.any;
import {BorcluEntity} from "@/entity/BorcluEntity";
import {HacizEntity} from "@/entity/Haciz/HacizEntity";
import {HacizTuru} from "@/enum/haciz/HacizTuru";
import {HarcKalemiEntity} from "@/entity/HarcKalemiEntity";
import {NotEntity} from "@/entity/NotEntity";
import {NotTuru} from "@/enum/NotTuru";
import {compilation} from "webpack";

export default class KapakHesabi {
    alacakKalemiData: Map<number, Map<number, number>> = new Map<number, Map<number, number>>();

    takipOncesiTahsilatData: Map<number, number> = new Map<number, number>();
    takipSonrasiTahsilatData: Map<number, number> = new Map<number, number>();

    //masrafData: Map<number, number> = new Map<number, number>();

    takipOncesiToplami: Map<number, number> = new Map<number, number>();
    takipSonrasiToplami: Map<number, number> = new Map<number, number>();

    genelToplam: Map<number, number> = new Map<number, number>();
    netKalan: Map<number, number> = new Map<number, number>();

    harcData: Map<number, number> = new Map<number, number>();
    kalanDataNew: Map<number, number> = new Map<number, number>();


    takipToplamiTahsilEdilmeyen: Map<number, number> = new Map<number, number>();
    takipSonrasiToplamiTahsilEdilmeyen: Map<number, number> = new Map<number, number>();
    tahsilEdilmeyenKalan: Map<number, number> = new Map<number, number>();

    takipOncesiToplamTutarData: Map<number, number> = new Map<number, number>();
    takipSonrasiToplamTutarData: Map<number, number> = new Map<number, number>();

    paraBirimleri: Map<number, ParaBirimiEntity> = new Map<number, ParaBirimiEntity>();
    alacakKalemiAlanlari: Array<number> = [];
    toplamTLTutarData: number = 0;

    takipSonrasiAlacakKalemiData: Map<number, Map<number, number>> = new Map<number, Map<number, number>>();
    takipSonrasialacakKalemiAlanlari: Array<number> = [];

    harcaEsasDegerTl: number = 0;

    duzenlenmisTakipSonrasiKalemler: Array<object> = []
    takipSonrasiKalemlerTakipKarti: Array<string> = [];

    IctkHacizler: Array<string> = [];
    IctkTahsilatlar: Array<string> = [];
    IctkHarclar: Array<string> = [];
    IctkHarclarHesapTablosu: Array<string> = [];
    IctkMasraflar: Array<string> = [];
    IctkMasraflarHesapTablosu: Array<string> = [];
    harcToplami: number = 0;
    masrafToplami: number = 0;

    odemeTaahhuduFaiz1: Array<string> = [];
    odemeTaahhuduFaiz2: Array<string> = [];
    odemeTaahhuduFaizToplamFaiz: string = "";


    odemeTaahhuduToplamFaiz: number = 0;
    odemeTaahhuduEsasBorc: number = 0;

    kalanToplami: number = 0;

    notlar: Array<string> = [];


    setTahsilatParaBirimleri(paraBirimi: ParaBirimiEntity) {
        if (!this.paraBirimleri.has(Number(paraBirimi.id))) {
            this.paraBirimleri.set(Number(paraBirimi.id), paraBirimi);
        }
    }

    setHarcData(paraBirimi: ParaBirimiEntity, harcTutari: number) {

        let toplam = Number(harcTutari);
        if (this.harcData.has(Number(paraBirimi.id))) {
            toplam += Number(this.harcData.get(Number(paraBirimi.id)));
        }
        this.harcData.set(Number(paraBirimi.id), toplam);

    }

    setTakipSonrasiIcraTakipKartiData(alacakKalemi: AlacakKalemiEntity) {

        let ictkObject = {
            "id": alacakKalemi.alacak_kalemi_alani_id,
            "tarih": alacakKalemi.tarih,
            "tutar": alacakKalemi.tutar,
            "paraBirimi": alacakKalemi.para_birimi.kisa_isim,
            "aciklama": alacakKalemi.aciklama,
            "harc_turu" : alacakKalemi.harc_turu,
            "masraf_cesidi":alacakKalemi.masraf_cesidi
        }

        this.duzenlenmisTakipSonrasiKalemler.push(ictkObject)

        //let aciklama=alacakKalemi.tahsil_edilmeyen_tutar+" "+alacakKalemi.para_birimi.kisa_isim+" "+alacakKalemi.aciklama
        //this.takipSonrasiKalemlerTakipKarti.push(aciklama)
    }

    setNotData(nots: Array<NotEntity>) {
        nots.forEach((not: NotEntity) => {
            if ((not.not_tur_id = NotTuru.haciz_satis_rehin) || (not.not_tur_id = NotTuru.tebligat)) {
                this.notlar.push(String(not.not));
            }
        })
    }


    setHarcaEsasDeger(paraBirimi: ParaBirimiEntity, alacakKalemi: AlacakKalemiEntity, takipTarihliKur: Array<TakipTarihliKurEntity>) {
        if (paraBirimi.id != ParaBirimi.TRY) {
            if (takipTarihliKur) {
                takipTarihliKur.forEach((ttk: TakipTarihliKurEntity) => {
                    if (alacakKalemi.para_birimi_id == ttk.para_birimi_id) {
                        this.harcaEsasDegerTl += alacakKalemi.takip_oncesi_tahsil_edilmeyen_tutar * ttk.tutar;
                    }
                })
            }
        } else {
            this.harcaEsasDegerTl += Number(alacakKalemi.takip_oncesi_tahsil_edilmeyen_tutar);
        }
    }

    getHacizLer(borclular: Array<BorcluEntity>) {
        borclular.forEach((borclu: BorcluEntity) => {
            borclu.hacizler?.forEach((haciz: HacizEntity | any) => {
                if (haciz.haciz_turu_id) {
                    let hacizText = "";
                    switch (haciz.haciz_turu_id) {
                        case HacizTuru.maasHacziTalebi:
                            hacizText = "Maaş Haczi : ";
                            hacizText += this.tarihStr(haciz.ekstra_data.haciz_tarihi) + " Şirket/Kişi Adı :"
                            if (haciz.isverenler.length > 0) {
                                let isverenText = "";
                                haciz.isverenler.forEach((isveren: any) => {
                                    isverenText += " " + isveren.isim;
                                    if (isveren.adres) {
                                        isverenText += "Adres :" + isveren.adres.isim
                                    }
                                })
                                hacizText += isverenText;
                                hacizText += " Maaş Tarihi : " + "----"
                                hacizText += " Gönderilme Tarihi : " + this.tarihStr(haciz.muzekkerenin_gonderildigi_tarih);
                                hacizText += " Tebliğ Tarihi : " + this.tarihStr(haciz.muzekkerenin_teblig_tarihi);
                                break;
                            }
                        case HacizTuru.aracHacziTalebi:
                            hacizText = "Araç Haczi : ";
                            hacizText += haciz.haciz_tarihi ? this.tarihStr(haciz.haciz_tarihi) : "";
                            hacizText += haciz.arac_plakasi ? " Plaka :" + haciz.arac_plakasi : "";
                            hacizText += haciz.arac_modeli ? " Model :" + haciz.arac_modeli : "";
                            hacizText += haciz.arac_markasi ? " Marka :" + haciz.arac_markasi : "";
                            hacizText += haciz.arac_turu ? " Araç Türü :" + haciz.arac_turu : "";
                            hacizText += haciz.arac_ozellikleri ? " Özellikler :" + haciz.arac_ozellikleri : "";
                            break;
                        case HacizTuru.ihbarnameTalebi89_1:
                            hacizText = "Alacak Haczi : ";
                            hacizText += " Kişi/Kurum Adı :";
                            hacizText += " 89/1 Tebliğ Tarihi :";
                            hacizText += " 89/1 İtiraz Tarihi :";
                            hacizText += " Kesinleşme Tutarı :";

                            break;
                        case HacizTuru.gemiHaczi:
                            hacizText = "Gemi Haczi : ";
                            hacizText += haciz.haciz_tarihi ? this.tarihStr(haciz.haciz_tarihi) : "";
                            hacizText += haciz.gemi_adi ? " Adı :" + haciz.gemi_adi : "";
                            hacizText += haciz.imo_no ? " IMO No :" + haciz.imo_no : "";
                            hacizText += haciz.takdir_edilen_deger ? " Değeri :" + haciz.takdir_edilen_deger : "";
                            hacizText += haciz.baglama_liman_adi ? " Bağlama Limanı :" + haciz.baglama_liman_adi : "";
                            hacizText += haciz.kayitli_oldugu_sicil ? " Kayıtlı Oldugu Sicil :" + haciz.kayitli_oldugu_sicil : "";
                            break;
                        case HacizTuru.hisseKarPayiHaczi:
                            hacizText = "Hisse Kar Payı Haczi : ";
                            hacizText += haciz.haciz_tarihi ? this.tarihStr(haciz.haciz_tarihi) : "";
                            hacizText += haciz.hisse_kar_payi_turu ? " Türü :" + haciz.hisse_kar_payi_turu : "";
                            hacizText += haciz.sirket_adi ? " Adı :" + haciz.sirket_adi : "";
                            hacizText += haciz.mersis_no ? " Mersis No :" + haciz.mersis_no : "";
                            hacizText += haciz.kiymet_takdiri[0]?.takdir_edilen_deger ? " Değeri :" + haciz.kiymet_takdiri[0]?.takdir_edilen_deger : "";
                            hacizText += " Hisse Türü :" + haciz.hisse_turu;
                            break;
                        case HacizTuru.markaPatentEndustriyelTasarimHaczi:
                            hacizText = "Marka Patent Haczi : ";
                            hacizText += haciz.haciz_tarihi ? this.tarihStr(haciz.haciz_tarihi) : "";
                            hacizText += haciz.marka_patent_adi ? " Marka Adı :" + haciz.marka_patent_adi : "";
                            hacizText += haciz.marka_patent_sicil_no ? " Sicil No :" + haciz.marka_patent_sicil_no : "";
                            hacizText += haciz.takdir_edilen_deger ? " Değeri :" + haciz.takdir_edilen_deger : "";
                            hacizText += haciz.marka_patent_tescil_tarihi ? " Tescil Tarihi :" + this.tarihStr(haciz.marka_patent_tescil_tarihi) : "";
                            break;
                        case HacizTuru.tasinirHacizTalebi:
                            hacizText = "Taşınır Haczi : ";
                            hacizText += this.tarihStr(haciz.haciz_tarihi)
                            hacizText += " İştirak Haczi :";
                            hacizText += " Haciz Toplam Tutarı :";
                            break;
                        case HacizTuru.gayrimenkulHacziTalebi:
                            hacizText = "Taşınmaz Haczi : ";
                            hacizText += this.tarihStr(haciz.haciz_tarihi)
                            hacizText += " İl :";
                            hacizText += " İlçe :";
                            hacizText += " Mahalle :";
                            hacizText += " Köy :";
                            hacizText += " Mevkii :";
                            hacizText += " Arsa Payı :";
                            hacizText += " Pafta :";
                            hacizText += " Ada :";
                            hacizText += " Parsel :";
                            hacizText += " Yüzölçümü(m2) :";
                            hacizText += " Vasıf :";
                            hacizText += " Çap(Kadastro) İstendi Mi? :";
                            hacizText += " İmar(Belediye) İstendi Mi? :";
                            break;
                        case HacizTuru.digerHaciz:
                            hacizText = "Diğer Haczi : ";
                            hacizText += this.tarihStr(haciz.haciz_tarihi)
                            hacizText += "";
                            break;
                    }
                    this.IctkHacizler.push(hacizText);


                }
            })


        })
    }

    getTahsilatlar(tahsilatlar: Array<TahsilatEntity>) {
        tahsilatlar.forEach((tahsilat: TahsilatEntity) => {
            this.IctkTahsilatlar.push(this.tarihStr(tahsilat.tarih) + " " + Number(tahsilat.tutar) + " " + tahsilat.para_birimi.kisa_isim + " " + tahsilat.tahsilat_turu.toUpperCase() + " Tahsilatı");
        })
    }

    getHarclar(harc: AlacakKalemiEntity) {
        this.harcToplami += Number(harc.tutar)
        this.IctkHarclarHesapTablosu.push(harc.harc_turu + ": " + harc.tutar + " " + harc.para_birimi.kisa_isim);
        this.IctkHarclar.push(harc.tutar + " " + harc.para_birimi.kisa_isim + " " + harc.harc_turu);
    }

    getMasraf(masraf: AlacakKalemiEntity) {
        this.masrafToplami += Number(masraf.tutar)
        this.IctkMasraflarHesapTablosu.push(masraf.masraf_cesidi + ": " + masraf.tutar + " " + masraf.para_birimi.kisa_isim);
        this.IctkMasraflar.push(masraf.tutar + " " + masraf.para_birimi.kisa_isim + " " + masraf.masraf_cesidi);
    }

    getOdemeTaahhuduEsasBorc(faizToplami: any) {
        this.odemeTaahhuduEsasBorc = faizToplami;
    }

    getOdemeTaahhuduFaiz1(alacakKalemi: AlacakKalemiEntity | any) {
        this.odemeTaahhuduFaiz1.push(alacakKalemi.aciklama)
    }

    getOdemeTaahhuduFaiz2(alacakKalemi: AlacakKalemiEntity | any) {
        this.odemeTaahhuduFaiz2.push(alacakKalemi.aciklama)
    }

    getOdemeTaahhuduToplamFaiz(faizKalemi: any) {
        this.odemeTaahhuduToplamFaiz = faizKalemi;
    }


    setTakipTarihliKur(takip_tarihli_kur: any) {
        let metin = ""
        takip_tarihli_kur.forEach((vtk: any) => {
            if (vtk.alacaklar[0].kur && vtk.alacaklar[0].para_birimi.kisa_isim !== 'TL') {
                vtk.alacaklar.forEach((alacak: any) => {
                    if (alacak.kur !== null && alacak.doviz_kur_tarihi == 'takip') {
                        metin += " X " + alacak.kur + " " + alacak.para_birimi.kisa_isim;
                    }
                });
            }
        })
        return metin
    }

    setVadeTarihliKur(vade_tarihli_kur: any) {
        let metin = ""
        vade_tarihli_kur.forEach((vtk: any) => {
            if (vtk.alacaklar[0].kur && vtk.alacaklar[0].para_birimi.kisa_isim !== 'TL') {
                vtk.alacaklar.forEach((alacak: any) => {
                    if (alacak.kur !== null && alacak.doviz_kur_tarihi == 'vade') {
                        metin += " X " + alacak.kur + " " + alacak.para_birimi.kisa_isim;
                    }
                });
            }
        })
        return metin
    }

    setTahsilatTarihliKur(tahsilat_tarihli_kur: any) {
        let metin = ""
        tahsilat_tarihli_kur.forEach((vtk: any) => {
            if (vtk.alacaklar[0].kur && vtk.alacaklar[0].para_birimi.kisa_isim !== 'TL') {
                vtk.alacaklar.forEach((alacak: any) => {
                    if (alacak.kur !== null && alacak.doviz_kur_tarihi == 'tahsilat') {
                        metin += " X " + alacak.kur + " " + alacak.para_birimi.kisa_isim;
                    }
                });
            }
        })
        return metin
    }


    setAlacakKalemiData(alacakKalemiAlaniId: number, paraBirimi: ParaBirimiEntity, tutar: number, tahsilEdilmeyenTutar: number) {
        this.setTakipOncesiToplamTutarData(Number(paraBirimi.id), tutar);
        this.setTakipToplamiData(paraBirimi, tahsilEdilmeyenTutar); //tahsilEdilmeyenTutar);
        this.setTakipOncesiTakipToplamiData(Number(paraBirimi.id));
        this.setGenelToplamData(Number(paraBirimi.id));
        //this.setNewNetKalanData(Number(paraBirimi.id), tahsilEdilmeyenTutar);
        this.setNetKalanData(Number(paraBirimi.id));

        if (!this.alacakKalemiData.has(alacakKalemiAlaniId)) {
            this.alacakKalemiData.set(alacakKalemiAlaniId, new Map<number, number>());
            this.alacakKalemiAlanlari.push(alacakKalemiAlaniId);
        }

        let alacakKalemi = this.alacakKalemiData.get(alacakKalemiAlaniId);
        if (alacakKalemi) {
            if (alacakKalemi.has(Number(paraBirimi.id))) {
                let oncekiTutar = alacakKalemi.get(Number(paraBirimi.id));
                tutar = Number(tutar) + Number(oncekiTutar);
            } else {
                if (!this.paraBirimleri.has(Number(paraBirimi.id))) {
                    this.paraBirimleri.set(Number(paraBirimi.id), paraBirimi);
                    if (!this.takipOncesiTahsilatData.has(Number(paraBirimi.id))) {
                        this.takipOncesiTahsilatData.set(Number(paraBirimi.id), 0);
                    }
                }
            }
            alacakKalemi.set(Number(paraBirimi.id), tutar);
        }
    }


    setTakipSonrasiAlacakKalemiData(alacakKalemiAlaniId: number, paraBirimi: ParaBirimiEntity, tutar: number, tahsilEdilmeyenTutar: number) {
        this.setTakipSonrasiTakipToplami(Number(paraBirimi.id), tutar);
        this.setTakipSonrasiTakipToplamiData(Number(paraBirimi.id));
        this.setGenelToplamData(Number(paraBirimi.id));
        this.setNewNetKalanData(Number(paraBirimi.id), tahsilEdilmeyenTutar);
        this.setNetKalanData(Number(paraBirimi.id));


        if (!this.takipSonrasiAlacakKalemiData.has(alacakKalemiAlaniId)) {
            this.takipSonrasiAlacakKalemiData.set(alacakKalemiAlaniId, new Map<number, number>());
            this.takipSonrasialacakKalemiAlanlari.push(alacakKalemiAlaniId);
        }

        let takipSonrasiAlacakKalemi = this.takipSonrasiAlacakKalemiData.get(alacakKalemiAlaniId);
        if (takipSonrasiAlacakKalemi) {
            if (takipSonrasiAlacakKalemi.has(Number(paraBirimi.id))) {
                let oncekiTutar = takipSonrasiAlacakKalemi.get(Number(paraBirimi.id));
                tutar = Number(tutar) + Number(oncekiTutar);
            } else {
                if (!this.paraBirimleri.has(Number(paraBirimi.id))) {
                    this.paraBirimleri.set(Number(paraBirimi.id), paraBirimi);
                    if (!this.takipSonrasiTahsilatData.has(Number(paraBirimi.id))) {
                        this.takipSonrasiTahsilatData.set(Number(paraBirimi.id), 0);
                    }
                }
            }
            takipSonrasiAlacakKalemi.set(Number(paraBirimi.id), tutar);
        }
    }


    getAlacakKalemi(alacakKalemiAlaniId: number, paraBirimiId: number): number {
        let tutar = Number(this.alacakKalemiData.get(alacakKalemiAlaniId)?.get(paraBirimiId));
        if (tutar) {
            return Number(tutar)
        }
        return 0;
    }

    getTakipSonrasiAlacakKalemi(alacakKalemiAlaniId: number, paraBirimiId: number): number {
        let tutar = Number(this.takipSonrasiAlacakKalemiData.get(alacakKalemiAlaniId)?.get(paraBirimiId));
        if (tutar) {
            return Number(tutar)
        }
        return 0;
    }

    setTakipOncesiTahsilatData(paraBirimi: ParaBirimiEntity, tutar: number) {
        let toplam = Number(tutar);
        if (this.takipOncesiTahsilatData.has(Number(paraBirimi.id))) {
            toplam += Number(this.takipOncesiTahsilatData.get(Number(paraBirimi.id)));
        }

        this.takipOncesiTahsilatData.set(Number(paraBirimi.id), toplam);
    }

    setTakipSonrasiTahsilatData(paraBirimi: ParaBirimiEntity, tutar: number) {
        let toplam = Number(tutar);
        if (this.takipSonrasiTahsilatData.has(Number(paraBirimi.id))) {
            toplam += Number(this.takipSonrasiTahsilatData.get(Number(paraBirimi.id)));
        }
        this.takipSonrasiTahsilatData.set(Number(paraBirimi.id), toplam);
    }


    private setTakipOncesiToplamTutarData(paraBirimiId: number, tutar: number) {
        let toplam = Number(tutar);
        if (this.takipOncesiToplamTutarData.has(paraBirimiId)) {
            let tutarData = this.takipOncesiToplamTutarData.get(paraBirimiId);
            toplam += Number(tutarData);
        }
        this.takipOncesiToplamTutarData.set(paraBirimiId, toplam);
    }

    private setTakipToplamiData(paraBirimi: ParaBirimiEntity, tahsilEdilmeyenTutar: number) {
        let toplam = Number(tahsilEdilmeyenTutar);

        if (this.takipToplamiTahsilEdilmeyen.has(Number(paraBirimi.id))) {
            let tutarData = this.takipToplamiTahsilEdilmeyen.get(Number(paraBirimi.id));
            toplam += Number(tutarData);
        }

        this.takipToplamiTahsilEdilmeyen.set(Number(paraBirimi.id), toplam);
    }

    private setTakipSonrasiTakipToplami(paraBirimiId: number, tahsilEdilmeyenTutar: number) {
        let toplam = Number(tahsilEdilmeyenTutar);

        if (this.takipSonrasiToplamiTahsilEdilmeyen.has(paraBirimiId)) {
            let tutarData = this.takipSonrasiToplamiTahsilEdilmeyen.get(paraBirimiId);
            toplam += Number(tutarData);
        }
        this.takipSonrasiToplamiTahsilEdilmeyen.set(paraBirimiId, toplam);
    }

    setTakipOncesiTakipToplamiData(paraBirimiId: number) {
        let takipOncesiToplam = 0;
        if (this.takipToplamiTahsilEdilmeyen.has(Number(paraBirimiId))) {
            takipOncesiToplam = Number(this.takipToplamiTahsilEdilmeyen.get(paraBirimiId));
        }
        this.takipOncesiToplami.set(paraBirimiId, Number(takipOncesiToplam));
    }

    setTakipSonrasiTakipToplamiData(paraBirimiId: number) {
        let takipSonrasiToplam = 0;
        if (this.takipSonrasiToplamiTahsilEdilmeyen.has(Number(paraBirimiId))) {
            takipSonrasiToplam = Number(this.takipSonrasiToplamiTahsilEdilmeyen.get(paraBirimiId));
        }
        this.takipSonrasiToplami.set(paraBirimiId, Number(takipSonrasiToplam));
    }

    setGenelToplamData(paraBirimiId: number) {
        let takipOncesiToplam = 0;
        let takipSonrasiToplam = 0;
        let harcToplam = 0;
        let genelToplam;

        if (this.takipToplamiTahsilEdilmeyen.has(Number(paraBirimiId))) {
            takipOncesiToplam = Number(this.takipToplamiTahsilEdilmeyen.get(paraBirimiId));
        }

        if (this.takipSonrasiToplamiTahsilEdilmeyen.has(Number(paraBirimiId))) {
            takipSonrasiToplam = Number(this.takipSonrasiToplamiTahsilEdilmeyen.get(paraBirimiId));
        }

        if (this.harcData.has(Number(paraBirimiId))) {
            harcToplam = Number(this.harcData.get(paraBirimiId));
        }

        /* console.log("takipSonrasiToplam ->", takipSonrasiToplam);
         console.log("takipOncesiToplam ->", takipOncesiToplam);
         console.log("harcToplam ->", harcToplam);
 */

        genelToplam = Number(takipSonrasiToplam) + Number(takipOncesiToplam) + Number(harcToplam);
        this.genelToplam.set(paraBirimiId, Number(genelToplam));
    }

    setNetKalanData(paraBirimiId: number) {
        let kalanToplam;
        //let takipSonrasiTahsilatDataToplami = 0; //TODO :: kalanın genel toplamla aynı gelmemesi için eklemiştim ama takip sornasi tahsilatta bi hata var o çözülsün sonra tekrar bak.

        if (this.genelToplam.has(Number(paraBirimiId))) {
            kalanToplam = Number(this.genelToplam.get(paraBirimiId));
        }

        /*if (this.takipSonrasiTahsilatData.has(Number(paraBirimiId))) {
            takipSonrasiTahsilatDataToplami = Number(this.takipSonrasiTahsilatData.get(paraBirimiId));
        }*/

        //kalanToplam = Number(kalanToplam) - Number(takipSonrasiTahsilatDataToplami);

        this.netKalan.set(paraBirimiId, Number(kalanToplam )- Number(this.takipSonrasiTahsilatData.get(Number(paraBirimiId))));
    }


    setNewNetKalanData(paraBirimiId: number, tahsilEdilmeyenTutar: number) {


        let toplam = Number(tahsilEdilmeyenTutar);

        if (this.tahsilEdilmeyenKalan.has(Number(paraBirimiId))) {
            let tutarData = this.tahsilEdilmeyenKalan.get(Number(paraBirimiId));
            toplam += Number(tutarData);
        }
        this.tahsilEdilmeyenKalan.set(Number(paraBirimiId), toplam);


    }

    /*getKalanNewData(paraBirimiId: number) {
        let kalanToplami = 0;
        console.log("XXXX ->", this.tahsilEdilmeyenKalan);
        if (this.tahsilEdilmeyenKalan.has(Number(paraBirimiId))) {
            kalanToplami += Number(this.tahsilEdilmeyenKalan.get(Number(paraBirimiId)));
        }

        this.kalanDataNew.set(Number(paraBirimiId), kalanToplami);
        console.log("kalan data new ->", this.kalanDataNew);
    }*/

    /* getNetKalan(paraBirimiId: number){
         let tutar = 0;
         console.log(this.tahsilEdilmeyenKalan)
         if (this.tahsilEdilmeyenKalan.has(Number(paraBirimiId))) {
             let oncekiTutar = this.tahsilEdilmeyenKalan.get(Number(paraBirimiId));
             tutar = Number(tutar) + Number(oncekiTutar);
         }
        return tutar;
     }*/


    tarihStr(val: Date | string): string {
        try {
            if (typeof val === "string") { //
                return val.substr(0, 10).split('-').reverse().join('/');
            } else if (typeof val === "object") {
                return val.toISOString().substr(0, 10).split('-').reverse().join('/');
            }
        } catch (e) {
        }
        return "";
    }

}