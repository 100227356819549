export enum OnemDerecesi {
    dusuk = 'dusuk',
    orta = 'orta',
    yuksek = 'yuksek',
    kritik = 'kritik'
}

export enum OnemDerecesiLabel {
    dusuk = 'Düşük',
    orta = 'Orta',
    yuksek = 'Yüksek',
    kritik = 'Kritik'
}

