
import {Component, Mixins, Prop} from "vue-property-decorator";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import BirIsinYapilmasiVeyaYapilmamasiAlacakFormWizard
  from "@/components/forms/form-wizard/alacak/BirIsinYapilmasiVeyaYapilmamasiAlacakFormWizard.vue";
import Dates from "@/components/inputs/Dates.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import TeminatForm from "@/components/forms/TeminatForm.vue";
import BirIsinYapilmasiVeyaYapilmamasiAlacakKalemiForm
  from "@/components/forms/alacak-kalemi/BirIsinYapilmasiVeyaYapilmamasiAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {TakipTipi} from "@/enum/TakipTipi";
import {HesapSekli} from "@/enum/HesapSekli";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    ItemArrayManager,
    AyEkleForm,
    BirIsinYapilmasiVeyaYapilmamasiAlacakKalemiForm,
    TeminatForm,
    Dates,
    BirIsinYapilmasiVeyaYapilmamasiAlacakFormWizard,
    IlamBilgileriForm,
    Tutar,
  },
})
export default class BirIsinYapilmasiVeyaYapilmamasiAlacakForm extends Mixins(ObjectInputMixin) {
  @Prop() takipTipiId!: number;
  @Prop() errorAlacakKalemleri!: boolean;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;


  isWizardComplete: boolean = false;
  alacakKalemiAlani = AlacakKalemiAlani;
  paraBirimi = ParaBirimi;
  takipTipi = TakipTipi;

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "alacak_kalemi_turu",
      text: "Alacak Kalemi Türü",
      renderer: (row) => row.alacak_kalemi_turu && row.alacak_kalemi_turu.isim,
    },
    {
      value: "faiz_baslangic_tarihi",
      text: "Faiz B. Tarihi",
      renderer: (row) => this.$helper.tarihStr(row.faiz_baslangic_tarihi),
    },
    {
      value: "takip_oncesi_faiz_turu.isim",
      text: "Takip Öncesi Faiz",
      renderer: (row) =>
          row.elle_faiz_mi ? ('Elle Faiz ' + this.$helper.tutarStr(row.elle_faiz_tutari)) : (row.takip_oncesi_faiz_turu ? row.takip_oncesi_faiz_turu.isim : 'Yasal Faiz'),
    },
    {
      value: "takip_sonrasi_faiz_turu.isim",
      text: "Takip Sonrası Faiz",
      renderer: (row) => row.takip_sonrasi_faiz_turu ? row.takip_sonrasi_faiz_turu.isim : 'Yasal Faiz',
    },
    {
      value: "tutar",
      text: "Tutar",
      renderer: (row) => this.$helper.tutarStr(row.tutar, row.para_birimi),
    },
  ];

  get defaultAlacakKalemi() {
    let alacakKalemi = new AlacakKalemiEntity();
    alacakKalemi.faiz_hesap_sekli = HesapSekli['365_gun'];
    alacakKalemi.para_birimi_id = this.paraBirimi.TRY;
    alacakKalemi.takip_oncesi_faiz_turu_id = this.takipTalebi.takip_alt_tipi.takip_oncesi_faiz_turu_id;
    alacakKalemi.takip_sonrasi_faiz_turu_id = this.takipTalebi.takip_alt_tipi.takip_sonrasi_faiz_turu_id;
    alacakKalemi.is_devam_eden_aylar = false;
    return alacakKalemi;
  }

  load() {
    this.$emit('load');
  }
}
