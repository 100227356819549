
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TakbisTalep} from "@/interface/IUyapTalep";
import BorcluTakbisPicker from "@/components/pickers/uyap/BorcluTakbisPicker.vue";
import {IBorcluTakbisSorgulamaSonuc} from "@/plugins/uyap-plugin/uyap/BorcluTakbisSorgulama";

@Component({
  components: {BorcluTakbisPicker}
})
export default class TakbisTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() dosyaId!: number;
  @Prop() kisiKurumId!: number;

  localValue!: TakbisTalep;

  onTakbisChange(takbisData: IBorcluTakbisSorgulamaSonuc) {
    this.localValue.il = takbisData.tasinmazDVO.il;
    this.localValue.ilce = takbisData.tasinmazDVO.ilce;
    this.localValue.mahalle = takbisData.tasinmazDVO.mahalle;
    this.localValue.ada = takbisData.tasinmazDVO.ada;
    this.localValue.parsel = takbisData.tasinmazDVO.parsel;
    this.input();
  }
}
