
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Faizler from "@/components/forms/Faizler.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {TakipEntity} from "@/entity/TakipEntity";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import IhtiyatiHacizEntity from "@/entity/IhtiyatiHacizEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";

@Component({
  components: {
    MahkemeSecim,
    DosyaEsasNumarasi,
    ParaBirimiPicker,
    FormWrapper,
    Tutar,
    Dates,
    Faizler,
  },
})
export default class IhtiyatiHacizAlacakKalemiForm extends Mixins(ObjectInputMixin) {
  @Prop() takip !: TakipEntity;
  @Prop() ucretler !: AlacakEntity;

  localValue!: IhtiyatiHacizEntity;
  kabulRedItems = [
    {text: "Kabul"},
    {text: "Red"}
  ]
  ihtiyatiHacizAlacakKalemi!: AlacakKalemiEntity;

  mounted() {
    this.yasalVekaletUcretiveIhtiyatiHacizMasrafi();
  }

  yasalVekaletUcretiveIhtiyatiHacizMasrafi() {
    this.ucretler?.alacak_kalemleri.forEach((alacakKalemi: AlacakKalemiEntity) => {
      if (alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.yasalVekaletUcreti) {
        this.localValue.yasal_vekalet_ucreti = alacakKalemi.tutar;
      }
      if (alacakKalemi.alacak_kalemi_alani_id == AlacakKalemiAlani.ihtiyatiHaciz) {
        this.localValue.ihtiyati_haciz_masrafi = alacakKalemi.tutar;
        this.ihtiyatiHacizAlacakKalemi = alacakKalemi;
      }
    })
  }

  async save() {
    if (this.localValue.id) {
      await this.$http.put("/api/v1/ihtiyati-haciz/" + this.localValue.id, this.localValue);
    } else {
      await this.$http.post("/api/v1/takip/" + this.takip.id + "/ihtiyati-haciz", this.localValue);
    }
    if (this.localValue.ihtiyati_haciz_masrafi != this.ihtiyatiHacizAlacakKalemi.tutar) {
      this.ihtiyatiHacizAlacakKalemi.tutar = this.localValue.ihtiyati_haciz_masrafi;
      this.$http.put("/api/v1/alacak-kalemi/" + this.ihtiyatiHacizAlacakKalemi.id, this.ihtiyatiHacizAlacakKalemi).then(() => (this.$emit('onSuccess')));
    } else {
      this.$emit('onSuccess');
    }
  }
}
