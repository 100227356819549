
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TaksitDuzenleForm from "@/components/anakart/taksitlendirme/TaksitDuzenleForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {TaksitEntity} from "@/entity/TaksitEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TaksitHesapAyarEntity} from "@/entity/TaksitHesapAyarEntity";
import {TakipEntity} from "@/entity/TakipEntity";
import OdemeTaahhuduTaslak from "@/components/OdemeTaahhuduTaslak.vue";

@Component({
  components: {OdemeTaahhuduTaslak, DeleteDialog, TaksitDuzenleForm, FormDialog}
})
export default class TaksitlendirmeList extends Mixins(ObjectInputMixin) {
  @Prop() loading!: boolean;
  @Prop() takip!: TakipEntity;
  @Prop() items!:any
  @Prop({default:false}) yazdirGorunurluk!:boolean
  @Prop({default:'Ödeme Detayları'}) baslikTaksit!:string

  @Ref("odemeTaahhuduTaslak")
  readonly odemeTaahhuduTaslak!: OdemeTaahhuduTaslak;

  dialog = false;
  printMode = false;
  multipleDelete = false;
  isKartNoVisible: boolean = true;
  secilenTaksitler = [];
  headers = [
    {text: "Tarih", value: "tarih"},
    {text: "Taksit Tutarı", value: "tutar", sortable: false},
    {text: "Faiz İşleyen Gün", value: "faiz_isleyen_gun", sortable: false},
    {text: "İşleyen Faiz", value: "isleyen_faiz", sortable: false},
    {text: "Toplam Faiz", value: "toplam_faiz", sortable: false},
    {text: "Kalan Ana Para", value: "kalan_ana_para", sortable: false},
    {text: "Kalan Faiz", value: "kalan_faiz", sortable: false},
    {text: "Kalan Borc", value: "kalan_borc", sortable: false},
    {text: "İşlemler", value: "actions", width: 100, align: 'center', sortable: false}
  ]

  onUpdate(item: any, index: number) {
    let newArray = [...this.takip.taksitler];
    newArray[index] = item;
    this.takip.taksitler = newArray;
    this.input(this.takip, true);
    this.$emit('updated', {index: item.index, type: 'update'});
  }

  onDelete(index: number | Array<TaksitEntity>, item: any|undefined) {
    if (typeof index == 'number') {
      this.takip.taksitler.splice(index, 1);
      this.input(this.takip, true);
      this.$emit('updated', {index: item.index, type: 'delete'})
    } else if (typeof index == 'object' && this.multipleDelete) {
      if (index.length > 0) {
        let indexler: Array<number> = [];
        index.forEach((v) => {
          indexler.push(v.index);
          this.takip.taksitler.splice( this.takip.taksitler.indexOf(v), 1);
        })
        this.secilenTaksitler = [];
        this.multipleDelete = false;
        this.input(this.takip, true);
        this.$emit('updated', {index: indexler, type: 'delete'})
      } else
        this.multipleDelete = false;
    } else
      this.multipleDelete = true;
  }

  ciktiAl() {
    this.printMode = true;
    this.$nextTick(() => {
      this.$helper.printComponent('taksitTablosu', true, true);
      this.printMode = false;
    });
  }

  print() {
    //yazdırda üst ve alttaki tarih/about:blank yazılarını kaldırır
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");
    if (header) {
      header.style.display = "none";
    }
    if (footer) {
      footer.style.display = "none";
    }
    this.odemeTaahhuduTaslak.print();

  }

  wordYazdir() {
    let belge: string = "";
    belge = "odemeTaahhuduTaslak";

    var header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'></head><body>";
    var footer = "</body></html>";
    var sourceHTML = header + (document.getElementById(belge)?.innerHTML) + footer;
    console.log(document.getElementById(belge));
    var source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = belge + ".doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }
}
