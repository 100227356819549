
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AvukatTalepPicker from "@/components/pickers/uyap/AvukatTalepPicker.vue";
import {UyapHacizEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapHacizEvrakTuru";
import EgmTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/EgmTalepEvrakForm.vue";
import MaasHacziTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/MaasHacziTalepEvrakForm.vue";
import TakbisTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/TakbisTalepEvrakForm.vue";
import BankaHacziTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/BankaHacziTalepEvrakForm.vue";
import SgkMossipHacziTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/SgkMossipHacziTalepEvrakForm.vue";
import AlacakliOlduguDosyaTalepEvrakForm
  from "@/components/forms/uyap-evrak/haciz-evrak/AlacakliOlduguDosyaTalepEvrakForm.vue";
import TasinirHacizTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/TasinirHacizTalepEvrakForm.vue";
import TasinirHacizTalimatiForm from "@/components/forms/haciz/haciz-turu/TasinirHacizTalimatiForm.vue";
import IhbarnameTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/IhbarnameTalepEvrakForm.vue";
import TasinirHacizTalimatiTalepEvrakForm
  from "@/components/forms/uyap-evrak/haciz-evrak/TasinirHacizTalimatiTalepEvrakForm.vue";

@Component({
  components: {
    TasinirHacizTalimatiTalepEvrakForm,
    IhbarnameTalepEvrakForm,
    TasinirHacizTalimatiForm,
    TasinirHacizTalepEvrakForm,
    AlacakliOlduguDosyaTalepEvrakForm,
    SgkMossipHacziTalepEvrakForm,
    BankaHacziTalepEvrakForm,
    TakbisTalepEvrakForm,
    MaasHacziTalepEvrakForm,
    EgmTalepEvrakForm,
    AvukatTalepPicker,
    FormWrapper
  }
})

export default class UyapHacizTalepForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) dosyaId !: string;
  @Prop({required: true}) kisiKurumId !: number;
  @Prop({required: true}) kisiMi !: boolean;

  uyapHacizEvrakTuru = UyapHacizEvrakTuru;

  async onTalepChange(e: any) {
    this.localValue = {...e};
    if ([ this.uyapHacizEvrakTuru.egm,
          this.uyapHacizEvrakTuru.takbis,
          this.uyapHacizEvrakTuru.sgk_mossip_haczi,
          this.uyapHacizEvrakTuru.alacakli_oldugu_dosya].indexOf(this.localValue.talepKodu) >= 0)
      this.localValue.sorguId = 0;
    else if (this.localValue.talepKodu == this.uyapHacizEvrakTuru.maas_haczi) {
      this.localValue.sorguId = '';
      this.localValue.maasininBirBoluDordu = false;
      this.localValue.alacaklarininTamami = false;
      this.localValue.emekliIkramiyeHaczi = false;
      this.localValue.nafaka = false;
    } else if (this.localValue.talepKodu == this.uyapHacizEvrakTuru.posta_ceki_haciz) {
      let data = await this.$uyap.BorcluPostaCeki().run({
        dosyaId: this.dosyaId,
        kisiKurumId: this.kisiKurumId
      });
      this.localValue.postaCekiExist = data.sorguSonucu;
      this.input();
    } else if ([this.uyapHacizEvrakTuru.banka_haczi,
                this.uyapHacizEvrakTuru.tasinir_haciz,
                this.uyapHacizEvrakTuru.tasinir_haciz_talimati].indexOf(this.localValue.talepKodu) >= 0) {
      this.localValue.sorguId = '';
      this.localValue.tarafList = '';
    } else if ([this.uyapHacizEvrakTuru.ihbarname89_1,
                this.uyapHacizEvrakTuru.ihbarname89_2,
                this.uyapHacizEvrakTuru.ihbarname89_3].indexOf(this.localValue.talepKodu) >= 0) {
      this.localValue.sorguId = '';
      this.localValue.dosyaId = this.dosyaId;
      this.localValue.kisiKurumId = this.kisiKurumId;
    }
    this.input();
  }
}
