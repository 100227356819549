

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {OnemDerecesi, OnemDerecesiLabel} from "@/enum/OnemDerecesi";

@Component({
  components: {EnumPicker}
})
export default class OnemPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
 @Prop() label!:string;
  onemDerecesi = OnemDerecesi;
  onemDerecesiLabel = OnemDerecesiLabel;
}
