export enum Hatirlat {
    dakika15 = 'dakika15',
    dakika30 = 'dakika30',
    saat1 = 'saat1'
}

export enum HatirlatLabel {
    dakika15 = '15 Dakika',
    dakika30 = '30 Dakika',
    saat1 = '1 Saat'
}