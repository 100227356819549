
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {AlacakliOlduguDosyaTalep} from "@/interface/IUyapTalep";
import BorcluIcraDosyasiPicker from "@/components/pickers/uyap/BorcluIcraDosyasiPicker.vue";
import {BorcluIcraDosyasiCevapRow} from "@/plugins/uyap-plugin/uyap/BorcluIcraDosyasi";

@Component({
  components: {BorcluIcraDosyasiPicker}
})
export default class AlacakliOlduguDosyaTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() dosyaId!: number;
  @Prop() kisiKurumId!: number;

  localValue!: AlacakliOlduguDosyaTalep;

  onDosyaChange(data: BorcluIcraDosyasiCevapRow) {
    this.localValue.kisiKurumId = data.kisiKurumId;
    this.localValue.birimAdi = data.birimAdi;
    this.localValue.dosyaNo = data.dosyaNo;
    this.localValue.dosyaTurKod = data.dosyaTurKod;
    this.input();
  }
}
