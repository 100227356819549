
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MalikiBilgisiForm from "@/components/forms/MalikiBilgisiForm.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import FormWrapper from "@/components/FormWrapper.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import Plaka from "@/components/inputs/Plaka.vue";

@Component({
  components: {Plaka, ItemArrayManager, MalikiBilgisiForm, FormWrapper},
})
export default class TasinirBilgisiForm extends Mixins(ObjectInputMixin) {
  @Prop() hideButtons!: boolean | string;
  aciklamayaEklenecekBilgi:string=''

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "isim",
      text: "İsim",
    },
    {
      value: "tc_no",
      text: "TC No",
    },
  ];

  get aciklama() {
    if (this.localValue.tur == 'arac' && this.localValue.plaka && this.localValue.marka_model) {
      return this.localValue.plaka.toUpperCase() + " plakalı " + this.localValue.marka_model + " marka modelli araç " + this.aciklamayaEklenecekBilgi;
    }
  }

  get isHideButtons() {
    return this.hideButtons || this.hideButtons === "";
  }

  @Watch("aciklama")
  onAciklamaChange() {
    this.localValue.aciklama = this.aciklama;
  }
}
