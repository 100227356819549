
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class DovizTakipSekliPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;

  items: Array<any> =[
    {
      text: "Vade Tarihi",
      value: "vade",
      aciklama: "Asıl alacak, vade tarihindeki kurdan TL’ye çevrilir. Takip öncesi ve sonrası faiz, TL üzerinden işler."
    },
    {
      text: "Takip Tarihi",
      value: "takip",
      aciklama: "Asıl alacak, takip tarihindeki kurdan TL’ye çevrilir. Takip öncesi faiz, döviz üzerinden; takip sonrası faiz TL üzerinden işler."
    },
    {
      text: "Tahsilat Tarihi",
      value: "tahsilat",
      aciklama: "Takip öncesi ve takip sonrası faiz, döviz üzerinden işler."
    }
  ];

}
