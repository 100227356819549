
import {Component, Mixins, Prop} from "vue-property-decorator";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {},
})
export default class DovizKur extends Mixins(SimpleInputMixin) {
  @Prop() label!: string;
  @Prop() paraBirimi?: ParaBirimiEntity;
  @Prop() color!: string;
  @Prop({}) rules!: string;
  @Prop() disabled: any;
  @Prop({}) reverse!: any;
  @Prop({}) dense !: boolean;
  @Prop() hideDetails!: boolean | string;
  @Prop() infoText!: string;
  localColor = this.color ? this.color : "";

  get isReverse() {
    return this.reverse || this.reverse === "";
  }

  get getLabel(): string {
    return this.label ? this.label : "Kur";
  }

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }


  keydown(){
    this.$emit('keydown')
  }


  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 44 && (this.localValue.match(/,/g) || []).length == 1)
      evt.preventDefault();
    else if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode != 44)
      evt.preventDefault();
    else
      return true;
  }
}
