
import {Component, Prop} from "vue-property-decorator";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import IsciAlacakKalemiForm from "@/components/forms/alacak-kalemi/IsciAlacakKalemiForm.vue";
import IlamBilgileriForm from "@/components/forms/alacak/IlamBilgileriForm.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    StepperValidationForm,
    Tutar,
    ParaBirimiPicker,
    Dates,
    IsciAlacakKalemiForm,
    ItemArrayManager,
    IlamBilgileriForm,
  },
})
export default class IsciAlacakForm extends AbstractFormWizard {
  @Prop({required: true}) takipId!: number;
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  activeStep = 1;

  stepperHeaders = [
    "İşçi Alacağı",
    "Alacak Kalemi"
  ];
  defaultMahkeme = {
    ilam: 1,
    yargiBirimi: 13,
    dosya: 1
  }
  mounted() {
    this.$emit('hide-button', true);
    if (!this.localValue.asil_alacaklar) {
      this.localValue.asil_alacaklar = [];
    }
  }

  previousStepRules() {
    return true;
  }

  nextStepRules() {
    return true;
  }
}
