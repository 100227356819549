

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {Hatirlat, HatirlatLabel} from "@/enum/Hatirlat";

@Component({
  components: {EnumPicker}
})
export default class HatirlatPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() disabled!: string | boolean;

  hatirlat = Hatirlat;
  hatirlatLabel = HatirlatLabel;

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }
}
