
import {Component, Prop, Watch} from "vue-property-decorator";
import {ParaBirimi} from "@/enum/ParaBirimi";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import ProtestoIhtarForm from "@/components/forms/ProtestoIhtarForm.vue";
import AsilAlacakKalemiForm from "@/components/forms/alacak-kalemi/AsilAlacakKalemiForm.vue";
import AyEkleForm from "@/components/forms/AyEkleForm.vue";
import {TakipTipi} from "@/enum/TakipTipi";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import StepperValidationForm from "@/components/StepperValidationForm.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    DovizTakipSekliPicker,
    Kur,
    StepperValidationForm,
    AyEkleForm,
    AsilAlacakKalemiForm,
    ProtestoIhtarForm,
    ItemArrayManager,
    ParaBirimiPicker,
    Tutar,
    Dates
  }
})

export default class CekAlacakFormWizard extends AbstractFormWizard {
  @Prop() takipTipiId!: number;
  @Prop() takipTalebi !: TakipTalebiEntity;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  activeStep = 1;
  stepperHeaders = [
      "Çek",
      "İhtar",
      "Alacak Kalemi"
  ];
  TakipTipi = TakipTipi;
  paraBirimi = ParaBirimi;
  dovizTakipSekli = DovizTakipSekli;

  cekSeriRules =[
    (value: any) => {
      return (!!value) || "Lütfen bu alanı doldurun."
    },
    (value: any) => {
      const isNumeric = /^[0-9]+$/.test(value);
      return (isNumeric) || "Lütfen sadece numerik rakamlar girin.";
    },
  ]

  get ihtarTuru(): string {
    if (this.takipTipiId && this.takipTipiId === TakipTipi.kambiyoSenetleriyleIflas || this.takipTipiId === TakipTipi.kambiyoSenetleriyleHaciz) {
      this.stepperHeaders = [
        "Çek",
        "Protesto",
        "Alacak Kalemi"
      ];
      return "protesto";
    }
    this.stepperHeaders = [
      "Çek",
      "İhtar",
      "Alacak Kalemi"
    ];
    return "ihtar";
  }


  @Watch('localValue.protesto_var_mi')
  onProtestoVarMiChange() {
    if (this.localValue.protesto_var_mi) {
      if (!this.localValue.protestolar && this.ihtarTuru == "protesto") {
        this.localValue.protestolar = [];
      }
    } else {
      delete this.localValue.protestolar;
    }
  }

  @Watch('localValue.ihtar_var_mi')
  onIhtarVarMiChange() {
    if (this.localValue.ihtar_var_mi) {
      if (!this.localValue.ihtarlar && this.ihtarTuru == "ihtar") {
        this.localValue.ihtarlar = [];
      }
    } else {
      delete this.localValue.ihtarlar;
    }
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY)
      delete this.localValue.doviz_kur_tarihi;
    this.input();
  }

  mounted() {
    this.$emit('hide-button', true);
    if (!this.localValue.asil_alacaklar) {
      this.localValue.asil_alacaklar = [];
    }
  }
}
