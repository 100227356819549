import { render, staticRenderFns } from "./AlacakBelgeTuruPicker.vue?vue&type=template&id=8f4d5b02"
import script from "./AlacakBelgeTuruPicker.vue?vue&type=script&lang=ts"
export * from "./AlacakBelgeTuruPicker.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports