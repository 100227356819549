
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import AlacakBelgeTuruPicker from "@/components/pickers/AlacakBelgeTuruPicker.vue";
import {AlacakBelgeTuruEntity} from "@/entity/AlacakBelgeTuruEntity";
import CekAlacakForm from "@/components/forms/alacak/CekAlacakForm.vue";
import FaturaAlacakForm from "@/components/forms/alacak/FaturaAlacakForm.vue";
import BonoAlacakForm from "@/components/forms/alacak/BonoAlacakForm.vue";
import KiraKontratiAlacakForm from "@/components/forms/alacak/KiraKontratiAlacakForm.vue";
import KiraSozlesmesiTahliyeTaahhuduAlacakForm
  from "@/components/forms/alacak/KiraSozlesmesiTahliyeTaahhuduAlacakForm.vue";
import RehinBelgesiAlacakForm from "@/components/forms/alacak/RehinBelgesiAlacakForm.vue";
import IpotekAkitTablosuAlacakForm from "@/components/forms/alacak/IpotekAkitTablosuAlacakForm.vue";
import NoterSenediAlacakForm from "@/components/forms/alacak/NoterSenediAlacakForm.vue";
import PoliceAlacakForm from "@/components/forms/alacak/PoliceAlacakForm.vue";
import SenetAlacakForm from "@/components/forms/alacak/SenetAlacakForm.vue";
import SozlesmeAlacakForm from "@/components/forms/alacak/SozlesmeAlacakForm.vue";
import TuketiciHakemHeyetiKarariAlacakForm from "@/components/forms/alacak/TuketiciHakemHeyetiKarariAlacakForm.vue";
import DigerAlacakForm from "@/components/forms/alacak/DigerAlacakForm.vue";
import MtsAlacakForm from "@/components/forms/alacak/MtsAlacakForm.vue";
import IsciAlacakForm from "@/components/forms/alacak/IsciAlacakForm.vue";
import TazminatAlacakForm from "@/components/forms/alacak/TazminatAlacakForm.vue";
import CocukTeslimiAlacakForm from "@/components/forms/alacak/CocukTeslimiAlacakForm.vue";
import KisiselIliskiAlacakForm from "@/components/forms/alacak/KisiselIliskiAlacakForm.vue";
import BirIsinYapilmasiVeyaYapilmamasiAlacakForm
  from "@/components/forms/alacak/BirIsinYapilmasiVeyaYapilmamasiAlacakForm.vue";
import IseIadeAlacakForm from "@/components/forms/alacak/IseIadeAlacakForm.vue";
import IrtifakVeyaIntifaHakkininKaldirilmasiAlacakForm
  from "@/components/forms/alacak/IrtifakVeyaIntifaHakkininKaldirilmasiAlacakForm.vue";
import IlamsizNafakaAlacakForm from "@/components/forms/alacak/IlamsizNafakaAlacakForm.vue";
import IlamliNafakaAlacakForm from "@/components/forms/alacak/IlamliNafakaAlacakForm.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";
import {TakipTipi} from "@/enum/TakipTipi";
import ApartmanAlacakForm from "@/components/forms/alacak/ApartmanAlacakForm.vue";

@Component({
  components: {
    ApartmanAlacakForm,
    FormWrapper,
    IlamsizNafakaAlacakForm,
    IlamliNafakaAlacakForm,
    IrtifakVeyaIntifaHakkininKaldirilmasiAlacakForm,
    IseIadeAlacakForm,
    BirIsinYapilmasiVeyaYapilmamasiAlacakForm,
    KisiselIliskiAlacakForm,
    CocukTeslimiAlacakForm,
    IsciAlacakForm,
    TazminatAlacakForm,
    DigerAlacakForm,
    TuketiciHakemHeyetiKarariAlacakForm,
    SozlesmeAlacakForm,
    SenetAlacakForm,
    PoliceAlacakForm,
    KiraSozlesmesiTahliyeTaahhuduAlacakForm,
    RehinBelgesiAlacakForm,
    IpotekAkitTablosuAlacakForm,
    KiraKontratiAlacakForm,
    BonoAlacakForm,
    FaturaAlacakForm,
    MtsAlacakForm,
    CekAlacakForm,
    NoterSenediAlacakForm,
    AlacakBelgeTuruPicker,
  },
})
export default class AlacakForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) takipTalebi!: TakipTalebiEntity;
  @Prop({default: false}) edit !: boolean;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;
  @Prop() mtsDurumu !: boolean;
  @Ref('formWrapper') formWrapper!: FormWrapper;
  AlacakBelgeTuru = AlacakBelgeTuru;
  errorAlacakKalemleri: boolean = false;
  hideButton: boolean = false;
  alacakBelgeTuruId:number=0


  @Watch("localValue.alacak_kalemleri")
  onAlacakKalemleriChange() {
    if (this.localValue.alacak_kalemleri && this.localValue.alacak_kalemleri.length > 0) {
      this.errorAlacakKalemleri = false;
    }
  }

  mounted() {
    if (this.mtsDurumu) {
      this.takipTipiChange({id: 9,isim:'',alacak_turleri:[]});
    }
  }

  // TODO: Watchla silmek yanlış en son kaydedildiği zaman switch kontrol edilip silinecek

  //protesto switchi false olduğunda tüm protestoları siler
  @Watch("localValue.protesto_var_mi")
  onProtestoVarMiChange() {
    if (!this.localValue.protesto_var_mi) {
      this.localValue?.protestolar?.forEach((protesto:any) => {
        if (protesto.id) {
          this.$http.delete(
              `/api/v1/alacak/${this.localValue.id}/protesto/${protesto.id}`
          );
          this.localValue.protestolar = []
        }
      });
    }
  }

  //ihtar switchi false olduğunda tüm ihtarları siler
  @Watch("localValue.ihtar_var_mi")
  onIhtarVarMiChange() {
    if (!this.localValue.ihtar_var_mi) {
      this.localValue?.ihtarlar?.forEach((ihtar:any) => {
        if (ihtar.id) {
          this.$http.delete(
              `/api/v1/alacak/${this.localValue.id}/ihtar/${ihtar.id}`
          );
          this.localValue.ihtarlar = []
        }
      });
    }
  }

  load() {
    if (this.localValue.id) {
      this.$http
          .get(`/api/v1/alacak/${this.localValue.id}`)
          .then((response) => {
            this.localValue = response.data;

            if (response.data.ihtarlar) {
              response.data.ihtarlar = response.data.ihtarlar.map(
                  (ihtar: any) => {
                    let data = {
                      ...ihtar,
                      ...ihtar.ihtar,
                    };

                    return data;
                  }
              );
            }

            if (response.data.protestolar) {
              response.data.protestolar = response.data.protestolar.map(
                  (protesto: any) => {
                    let data = {
                      ...protesto,
                      ...protesto.protesto,
                    };

                    return data;
                  }
              );
            }
            if (response.data.asil_alacaklar) {
              response.data.alacak_kalemleri = [
                ...response.data.asil_alacaklar
              ]
            }
          });
    }
  }

  takipTipiChange(alacakBelgeTuru: AlacakBelgeTuruEntity) {
    this.localValue = new AlacakEntity();
    this.localValue.alacak_belge_turu_id = alacakBelgeTuru.id;
    if ((this.localValue.alacak_belge_turu_id === AlacakBelgeTuru.cocukTeslimi) ||
        (this.localValue.alacak_belge_turu_id === AlacakBelgeTuru.kisiselIliski) ||
        (this.localValue.alacak_belge_turu_id === AlacakBelgeTuru.birIsinYapilmasiYaDaYapilmamasi && this.takipTalebi.takip_tipi.eski_no == "54") ||
        (this.localValue.alacak_belge_turu_id === AlacakBelgeTuru.tazminat)
    ) {
      this.hideButton = false;
    }
    this.alacakBelgeTuruId=this.localValue.alacak_belge_turu_id

    this.input();
  }

  save() {
    if (this.localValue.asil_alacaklar.length > 0 ||
        this.takipTalebi.takip_tipi_id == TakipTipi.menkulTeslimi ||
        this.takipTalebi.takip_tipi_id == TakipTipi.cocukTeslimi||
        this.takipTalebi.takip_tipi_id == TakipTipi.tahliye) {
      this.formWrapper.onFly = true;
      if (this.localValue.id)
        this.update().finally(() => {
          this.errorAlacakKalemleri = false;
          this.formWrapper.onFly = false;
        });
      else
        this.create().finally(() => {
          this.errorAlacakKalemleri = false;
          this.formWrapper.onFly = false;
        });
    } else {
      this.errorAlacakKalemleri = true;
    }
  }

  async create() {
    if (this.localValue.takip_tarihli_kurlar)
      await this.addTakipTarihliKurlar();
    if(this.localValue.alacak_belge_turu_id!=this.AlacakBelgeTuru.apartman_alacagi){
      this.localValue.gecikme_tazminati_mi=false
    }
    const response = await this.$http.post(`/api/v1/takip-talebi/${this.takipTalebi.id}/alacak`, {
      ...this.localValue,
      takip_tipi_id: this.takipTalebi.takip_tipi_id,
    }).catch(error => StatusCodeHelper.error(error));
    this.localValue.id = response.data.id;
    await this.checkForm(response.data.id, this.localValue);
    this.$emit("onSuccess");
  }

  async update() {
    if (this.localValue.takip_tarihli_kurlar)
      await this.addTakipTarihliKurlar();
    if(this.localValue.alacak_belge_turu_id!=this.AlacakBelgeTuru.apartman_alacagi){
      this.localValue.gecikme_tazminati_mi=false
    }
    const response = await this.$http.put(`/api/v1/alacak/${this.localValue.id}`, {
      ...this.localValue,
      takip_tipi_id: this.takipTalebi.takip_tipi_id,
    }).catch(error => StatusCodeHelper.error(error));
    await this.checkForm(response.data.id, this.localValue);
    this.$emit("onSuccess");
  }

  async addTakipTarihliKurlar() {
    let kayitli = this.localValue.takip_tarihli_kurlar.filter((kur:any) => kur.id);
    let yeni = this.localValue.takip_tarihli_kurlar.filter((kur:any) => !kur.id);
    if (kayitli.length > 0) {
      for (const kur of kayitli) {
        await this.$http.put(`/api/v1/takip-tarihli-kur/${kur.id}`, {...kur, tarih: this.takipTalebi.takipTarihi});
      }
    }
    if (yeni.length > 0) {
      for (const kur of yeni) {
        if (this.localValue.para_birimi_id == kur.para_birimi_id || this.localValue.asil_alacaklar.find((alacak:any) => alacak.para_birimi_id == kur.para_birimi_id)) {
          await this.$http.post(`/api/v1/takip/${this.takipTalebi.takip_id}/takip-tarihli-kur`, {
            ...kur,
            tarih: this.takipTalebi.takipTarihi
          });
        }
      }
    }
  }

  async checkForm(id: number, data: any) {

    if (data.ihtarlar && data.ihtarlar.length > 0) {
      let ihtar: any;
      for (ihtar of data.ihtarlar) {
        let data = {
          ...ihtar,
          para_birimi_id: 1,
          noter_id: ihtar.noter && ihtar.noter.id,
          aciklama: `İhtar Alacağı`,
        };
        if (data.elle_faiz_oran_mi) {
          data.takip_oncesi_faiz_turu_id = await this.saveFaizTuru(data.takip_oncesi_faiz_turu);
          data.takip_sonrasi_faiz_turu_id = data.takip_oncesi_faiz_turu_id;
        }
        await this.saveIhtar(id, data);
      }
    }

    if (data.protestolar && data.protestolar.length > 0) {
      let protesto: any;
      for (protesto of data.protestolar) {
        let data = {
          ...protesto,
          para_birimi_id: 1,
          noter_id: protesto.noter && protesto.noter.id,
          aciklama: `Protesto Alacağı`,
        };
        if (data.elle_faiz_oran_mi) {
          data.takip_oncesi_faiz_turu_id = await this.saveFaizTuru(data.takip_oncesi_faiz_turu);
          data.takip_sonrasi_faiz_turu_id = data.takip_oncesi_faiz_turu_id;
        }
        await this.saveProtesto(id, data);
      }
    }

    if (data.tasinirlar && data.tasinirlar.length > 0) {
      for (let tasinir of data.tasinirlar) {
        await this.saveTasinirlar(id, tasinir);
      }
    }

    if (data.tasinmazlar && data.tasinmazlar.length > 0) {
      for (let tasinmaz of data.tasinmazlar) {
        await this.saveTasinmazlar(id, tasinmaz);
      }
    }

    if (data.asil_alacaklar && data.asil_alacaklar.length > 0) {
      let alacak_kalemi: any;
      for (alacak_kalemi of data.asil_alacaklar) {
        let alacakKalemiData = {
          alacak_id: id,
          alacak_kalemi_alani_id: 1,
          ...(alacak_kalemi.alacak_kalemi_turu && {alacak_kalemi_turu_id: alacak_kalemi.alacak_kalemi_turu.id}),
          ...(alacak_kalemi.borclu && {borclu_id: alacak_kalemi.borclu.id}),
          ...alacak_kalemi,
        };
        if (alacakKalemiData.elle_faiz_oran_mi) {
          alacakKalemiData.takip_oncesi_faiz_turu_id = await this.saveFaizTuru(alacakKalemiData.takip_oncesi_faiz_turu);
          alacakKalemiData.takip_sonrasi_faiz_turu_id = alacakKalemiData.takip_oncesi_faiz_turu_id;
        }
        await this.saveAlacakKalemi(id, alacakKalemiData);
      }
    }
  }

  async saveFaizTuru(faizTuru: FaizTuruEntity) {
    let response = null;
    if (faizTuru && faizTuru.id != null) {
      response = await this.$http.put("/api/v1/faiz-turu/" + faizTuru.id, faizTuru);
    } else {
      if (this.$route.params.id) {
        response = await this.$http.post("/api/v1/takip/" + this.$route.params.id + "/faiz-turu/", faizTuru);
      } else {
        response = await this.$http.post("/api/v1/faiz-turu/", faizTuru);
      }
    }
    faizTuru.id = response.data.id;
    await this.saveFaizOranlar(faizTuru);
    return response.data.id;
  }

  async saveFaizOranlar(item: FaizTuruEntity) {
    if (item.faiz_oranlari) {
      for (const faizOran of item.faiz_oranlari) {
        if (faizOran && faizOran.id != null) {
          await this.$http.put("/api/v1/faiz-oran/" + faizOran.id, faizOran);
        } else {
          faizOran.faiz_turu_id = item.id;
          await this.$http.post("/api/v1/faiz-oran/", faizOran);
        }
      }
    }
  }

  async saveIhtar(id: number, data: any) {
    if (data.id) {
      return await this.$http.put(`/api/v1/alacak/${id}/ihtar`, data).catch(error => StatusCodeHelper.error(error));
    }
    return await this.$http.post(`/api/v1/alacak/${id}/ihtar`, data).catch(error => StatusCodeHelper.error(error));
  }

  async saveProtesto(id: number, data: any) {
    if (data.id) {
      return await this.$http.put(`/api/v1/alacak/${id}/protesto`, data).catch(error => StatusCodeHelper.error(error));
    }
    return await this.$http.post(`/api/v1/alacak/${id}/protesto`, data).catch(error => StatusCodeHelper.error(error));
  }

  async saveAlacakKalemi(id: number, data: any) {
    if(this.localValue.alacak_belge_turu_id!=this.AlacakBelgeTuru.apartman_alacagi){
      data.gecikme_tazminati_mi=false
    }
    if (data.id) {
      return await this.$http.put(`/api/v1/alacak-kalemi/${data.id}`, data).catch(error => StatusCodeHelper.error(error));
    }
    return await this.$http.post(`/api/v1/alacak/${id}/alacak-kalemi`, data).catch(error => StatusCodeHelper.error(error));
  }

  async saveTasinirlar(id: number, data: any) {
    if (data.id) {
      return await this.$http.put(`/api/v1/tasinir/${data.id}`, data).catch(error => StatusCodeHelper.error(error));
    }
    return await this.$http.post(`/api/v1/alacak/${id}/tasinirlar`, data).catch(error => StatusCodeHelper.error(error));
  }

  async saveTasinmazlar(id: number, data: any) {
    if (data.id) {
      return await this.$http.put(`/api/v1/tasinmaz/${data.id}`, data).catch(error => StatusCodeHelper.error(error));
    }
    return await this.$http.post(`/api/v1/alacak/${id}/tasinmazlar`, data).catch(error => StatusCodeHelper.error(error));
  }

  alacakKalemindenElleFaizGetir(alacakKalemi: AlacakKalemiEntity) {
    return alacakKalemi.children?.filter((child: AlacakKalemiEntity) => child.alacak_kalemi_alani_id == AlacakKalemiAlani.takipOncesiFaiz && !child.is_calculated)[0];
  }
}
