
import {Component, Prop, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipDurumu} from "@/enum/TakipDurumu";

@Component({
  components: {
    KapakHesabiInfo: () => import('@/components/infos/KapakHesabiInfo.vue')
    , FormWrapper, Dates
  },
})


export default class HesapTarihiForm extends Vue {
  @Prop()hesapTarihi!: Date;
  item: TakipEntity = new TakipEntity();
  oldHesapTarihi!: Date;

  calculate() {
    if (this.hesapTarihi && this.oldHesapTarihi!=this.hesapTarihi) {
      if (this.item.takip_durumu===TakipDurumu.kapali){
        if (this.$helper.tarihStr(new Date())<this.$helper.tarihStr(this.hesapTarihi)){
          this.$http
              .post("/api/v1/takip/" + this.$route.params.id + "/faiz")
              .then((response) => {
                this.item = response.data;
                this.$emit('onSuccess');
              });
        }else{
          this.oldHesapTarihi=this.hesapTarihi;
          this.$http
              .post("/api/v1/takip/" + this.$route.params.id + "/faiz", {hesap_tarihi: this.hesapTarihi})
              .then((response) => {
                this.item = response.data;
                this.$emit('onSuccess');
              });
        }
      }else{
        this.oldHesapTarihi=this.hesapTarihi;
        this.$http
            .post("/api/v1/takip/" + this.$route.params.id + "/faiz", {hesap_tarihi: this.hesapTarihi})
            .then((response) => {
              this.item = response.data;
              this.$emit('onSuccess');
            });
      }


    }
  }

  onClose() {
    this.$emit("onClose");
  }
}
