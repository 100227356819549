export enum DovizTakipSekli {
    vade = "vade",
    takip = "takip",
    tahsilat = "tahsilat"
}

export enum DovizTakipSekliLabel {
    vade = "Vade Tarihi",
    takip = "Takip Tarihi",
    tahsilat = "Tahsilat Tarihi"
}