import {Mixins, Vue} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

export class AbstractFormWizard extends Mixins(ObjectInputMixin) {
    activeStep: number = 0;
    stepperHeaders: any = [];

    save() {
        this.$emit('onSuccess');
        this.$emit('hide-button', false);
    }

    close() {
        this.$emit('hide-button', false);
        this.$emit('onClose');
    }

    nextStep() {
        if (this.activeStep === this.stepperHeaders.length) {
            this.save();
        } else if (this.activeStep < this.stepperHeaders.length) {
            this.activeStep++;
        }
        this.nextStepRules();
    }

    nextStepRules(): void {
        if (this.activeStep == 2) {
            if (!this.localValue.protesto_var_mi && !this.localValue.ihtar_var_mi) {
                if (this.activeStep === this.stepperHeaders.length)
                    this.save();
                else
                    this.activeStep++;
            }
        }
    };

    previousStep() {
        if (this.activeStep > 1) {
            this.activeStep--;
        }
        this.previousStepRules();
    }

    previousStepRules() {
        if (this.activeStep == 2) {
            if (!this.localValue.protesto_var_mi && !this.localValue.ihtar_var_mi) {
                this.activeStep--;
            }
        }
    }

}

